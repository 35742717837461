// Import the resources
import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import InitialPage from './pages/initial/initialPage';
import SideBar from './components/container/sideBar';
import Users from './components/container/sites/users';
import Anomaly from './components/container/sites/anomaly';
import Policy from './components/container/sites/policy';
import Block from './components/container/sites/block';
import Summary from './components/container/sites/summary';
import Intercompany from './components/container/sites/intercompany';
import Negotiation from './components/container/sites/negotiation';
import Material from './components/container/sites/material';
import ProtectedRoutes from './components/protectedRoutes/ProtectedRoutes';
import ProtectedIntercompany from './components/protectedRoutes/protectedIntercompany/ProtectedIntercompany';
import ProtectedUser from './components/protectedRoutes/protectedUser/ProtectedUser';
import ProtectedAdminRoutes from './components/protectedRoutes/ProtectedAdminRoutes';
import ProtectedBlock from './components/protectedRoutes/protectedBlock/ProtectedBlock';
import CountriesContextProvider from './context/CountriesContext';
import RoleContextProvider from './context/RoleContext';
import PlantsContextProvider from './context/PlantsContext';
import PermissionContextProvider from './context/PermissionContext';
import CountryComponentContextProvider from './context/CountryComponentContext';
import UserContextProvider from './context/UserContext';
import RefreshContextProvider from './context/RefreshContext';
import ZoneContextProvider from './context/ZoneContext';
import CurrencyContextProvider from './context/CurrencyContext';

function App() {
  return (
    <div style={{ height: "100vh" }}>
      {/* Space to create the routes */}
      <BrowserRouter>
        {/* Space for the contexts */}
        <ZoneContextProvider>
          <CurrencyContextProvider>
            <PermissionContextProvider>
              <CountriesContextProvider>
                <RoleContextProvider>
                  <PlantsContextProvider>
                    <CountryComponentContextProvider>
                      <UserContextProvider>
                        <RefreshContextProvider>
                          {/* Create the routes*/}
                          <Routes>
                            <Route path="/" element={<InitialPage />} />
                            <Route element={<ProtectedRoutes />}>
                              <Route path="/inventory" element={<SideBar />}>
                                <Route path="" element={<Navigate to="/" />} />
                                <Route element={<ProtectedUser/>}>
                                  <Route path="users" element={<Users />} />
                                </Route>
                                <Route element={<ProtectedAdminRoutes />}>
                                  <Route path="anomalies" element={<Anomaly />} />
                                </Route>
                                <Route path="policy" element={<Policy />} />
                                <Route element={<ProtectedBlock/>}>
                                  <Route path="block" element={<Block />} />
                                </Route>
                                <Route path="summary" element={<Summary />} />
                                <Route element={<ProtectedIntercompany />}>
                                  <Route path="intercompany" element={<Intercompany />} />
                                </Route>
                                <Route path="negotiation" element={<Negotiation />} />
                                <Route path="material" element={<Material />} />
                              </Route>
                            </Route>
                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </RefreshContextProvider>
                      </UserContextProvider>
                    </CountryComponentContextProvider>
                  </PlantsContextProvider>
                </RoleContextProvider>
              </CountriesContextProvider>
            </PermissionContextProvider>
          </CurrencyContextProvider>
        </ZoneContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
