import { createContext, useState, useMemo } from "react";
import PropTypes from 'prop-types';

export const RefreshContext = createContext()

/**
 * @context
 * @description This context contains the current state to refresh the summary view
*/
const RefreshContextProvider = ({children}) => {

  /**
   * @state
   * @type {boolean}
   * @default false
   * @description State to save the value
  */
  const [refresh, setRefresh] = useState(false);
  const [refreshBenefits, setRefreshBenefits] = useState(false);

  /**
   * @memo
   * @description Create an object that contains the current state of 'refresh' and a function to 
   * update it ('setRefresh').
   * This object is passed as a value to the context provider to allow access and modification of the 
   * state of refresh from child components.
  */
  const data = useMemo(() => ({
    refresh, 
    setRefresh,
    refreshBenefits,
    setRefreshBenefits
  }), [refresh, setRefresh, refreshBenefits, setRefreshBenefits]);

  return (
    <RefreshContext.Provider value={data}>
        {children}
    </RefreshContext.Provider>
  )
}

RefreshContextProvider.propTypes = {
  children: PropTypes.node
}

export default RefreshContextProvider