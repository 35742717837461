import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

/**
 * @function getSummary
 * @description Sends a GET request to the get-ini-summary endpoint of the API.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request, the data is displayed on this objects:
 * responseData
*/
function getSummary(country, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/get-ini-summary/${country.normalCountry}/?zone=${zone}&${apiParam}`, config).then(res => {
        const responseData = res.data;
        return {responseData};
    });
}

export { getSummary };