import React from 'react'
import { formatNewNumber } from '../../../models/generics';
import PropTypes from 'prop-types';

const LabelMaterial = (props) => {

    const { x, y, stroke, value  } = props;

    return (
        <text x={x} y={y} dy={-4} fill={stroke} className='text-black text-xs 4xl:text-sm' textAnchor="middle">
            {value !== 0 ? formatNewNumber(value, "standard") : null}
        </text>
    )
}

LabelMaterial.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    value: PropTypes.number,
    stroke: PropTypes.number
}

export default LabelMaterial