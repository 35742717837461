import { createContext, useState, useMemo } from "react";
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

export const PlantsContext = createContext()

/**
 * @context
 * @description This context contains the user's plants
*/
const PlantsContextProvider = ({children}) => {

  /**
   * @state
   * @type {array}
   * @default []
   * @description State to save the user's plants
  */
  const [plants, setPlants] = useState(() => {
    return Cookies.get("plants_user") ? Cookies.get("plants_user").split(",") : []
  });

  /**
   * @memo
   * @description Create an object that contains the current state of 'plants' and a function to 
   * update it ('setPlants').
   * This object is passed as a value to the context provider to allow access and modification of the 
   * state of plants from child components.
  */
  const data = useMemo(() => ({
    plants, 
    setPlants
  }), [plants, setPlants]);

  return (
    <PlantsContext.Provider value={data}>
        {children}
    </PlantsContext.Provider>
  )
}

PlantsContextProvider.propTypes = {
  children: PropTypes.node
}

export default PlantsContextProvider
