import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    anomalies: [],
    searchOption: "months",
    filterOption: "",
    initialMonth: 0,
    endMonth: 0,
    initialDate: null,
    endDate: null
}

export const anomalySlice = createSlice({
    name: 'anomaly',
    initialState,
    reducers: {
        addAnomalies: (state, action) => {
            state.anomalies = action.payload;
        },
        updateAnomalies: (state, action) => {
            const updateAnomaly = action.payload;
            const index = state.anomalies.findIndex(item => item.purchase_order === updateAnomaly.purchase_order);
            if (index !== -1) {
                state.anomalies[index] = updateAnomaly;
            }
        },
        addSearchOption: (state, action) => {
            state.searchOption = action.payload;
        },
        addFilterOption: (state, action) => {
            state.filterOption = action.payload;
        },
        addInitialMonth: (state, action) => {
            state.initialMonth = action.payload;
        },
        addEndMonth: (state, action) => {
            state.endMonth = action.payload;
        },
        addInitialDate: (state, action) => {
            state.initialDate = action.payload;
        },
        addEndDate: (state, action) => {
            state.endDate = action.payload;
        },

        /**
         * @function resetStateAnomalies
         * @description A reducer to reset the states
        */
        resetStateAnomalies: () => initialState
    }
})

export const { addAnomalies, updateAnomalies, addSearchOption, addFilterOption, addInitialMonth, addEndMonth, addInitialDate, addEndDate, resetStateAnomalies } = anomalySlice.actions;
export default anomalySlice.reducer;