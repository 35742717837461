import React, {useContext} from 'react'
import { formatCurrency } from '../../../models/generics';
import { CurrencyContext } from '../../../context/CurrencyContext';
import PropTypes from 'prop-types';

const LabelPolicy = (props) => {
    
  const { x, y, value, height, width  } = props;
  
  const yPos = isNaN(y) ? 0 : y + height / 2;

  const {currency, locale, changeRateSelected} = useContext(CurrencyContext)

  return (
    <text x={x + width / 2} y={yPos} className='text-black font-semibold text-xs xl:text-base 4xl:text-xl' textAnchor="middle" dominantBaseline="middle">
        {formatCurrency(value, "standard", currency, locale, changeRateSelected)}
    </text>
  )
}

LabelPolicy.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default LabelPolicy
