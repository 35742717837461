// Import the resources
import React, {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import { formatCurrency, formatNewNumber } from '../../models/generics';
import { CurrencyContext } from '../../context/CurrencyContext';
import PropTypes from 'prop-types';

/**
 * @component
 * @description This component show the table in the summary report view
*/
const TableSummary = ({summaryData}) => {
    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    const {currency, locale, changeRateSelected} = useContext(CurrencyContext)
    
    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the data for the table
    */
    const [dataTable, setDataTable] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the data for the table and recover it after reorder it.
    */
    const [dataOriginal, setDataOriginal] = useState([]);

    /**
     * @effect
     * @description This effect is executed when the summaryData prop is updated. 
     * Updates the states:
     * dataTable, dataTableOriginal and orderSummary
    */
    useEffect(() => {
        setDataTable(summaryData)
        setDataOriginal(summaryData)
        setOrderSummary("")
    }, [summaryData]);

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local States to define the order in the table
    */
    const [orderSummary, setOrderSummary] = useState("");

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local state to save the text to filter the data in the table
    */
    const [textFilter, setTextFilter] = useState("");

    /**
     * @function
     * @name handleFilterChange
     * @description Function to update the filterText state
     * @param {string} event - Text in the input
    */
    const handleFilterChange = (event) => {
        setTextFilter(event.target.value);
    }

    /**
     * @constant
     * @type {array}
     * @description Show the data filtered with filterText state
    */
    const filteredData = dataTable.filter(item =>
        item.plant.includes(textFilter.toUpperCase())
    );

    /**
     * @function
     * @name handleOrderByHand
     * @description Order the data in table by on_hand
     * @param orden - Order specified
    */
    const handleOrderByHand = (orden) => {
        const sortedData = [...dataTable];
        if (orden === "asc") {
            sortedData.sort((a, b) => a.on_hand - b.on_hand);
            setDataTable(sortedData);
            setOrderSummary("hand_asc")
        } else {
            sortedData.sort((a, b) => b.on_hand - a.on_hand);
            setDataTable(sortedData);
            setOrderSummary("hand_desc")
        }
    }

    /**
     * @function
     * @name handleOrderByPotential
     * @description Order the data in table by potential_reduction
     * @param orden - Order specified
    */
    const handleOrderByPotential = (orden) => {
        const sortedData = [...dataTable];
        if (orden === "asc") {
            sortedData.sort((a, b) => a.potential_reduction - b.potential_reduction);
            setDataTable(sortedData);
            setOrderSummary("potential_asc")
        } else {
            sortedData.sort((a, b) => b.potential_reduction - a.potential_reduction);
            setDataTable(sortedData);
            setOrderSummary("potential_desc")
        }
    }

    /**
     * @function
     * @name handleOrderByRisk
     * @description Order the data in table by on_risk_materials
     * @param orden - Order specified
    */
    const handleOrderByRisk = (orden) => {
        const sortedData = [...dataTable];
        if (orden === "asc") {
            sortedData.sort((a, b) => a.on_risk_materials - b.on_risk_materials);
            setDataTable(sortedData);
            setOrderSummary("risk_asc")
        } else {
            sortedData.sort((a, b) => b.on_risk_materials - a.on_risk_materials);
            setDataTable(sortedData);
            setOrderSummary("risk_desc")
        }
    }

    /**
     * @function
     * @name handleOrderByOverstock
     * @description Order the data in table by overstocked_materials
     * @param orden - Order specified
    */
    const handleOrderByOverstock = (orden) => {
        const sortedData = [...dataTable];
        if (orden === "asc") {
            sortedData.sort((a, b) => a.overstocked_materials - b.overstocked_materials);
            setDataTable(sortedData);
            setOrderSummary("overstock_asc")
        } else {
            sortedData.sort((a, b) => b.overstocked_materials - a.overstocked_materials);
            setDataTable(sortedData);
            setOrderSummary("overstock_desc")
        }
    }

    /**
     * @function
     * @name handleOrderByAgreed
     * @description Order the data in table by approved_reduction
     * @param orden - Order specified
    */
    const handleOrderByAgreed = (orden) => {
        const sortedData = [...dataTable];
        if (orden === "asc") {
            sortedData.sort((a, b) => a.approved_reduction - b.approved_reduction);
            setDataTable(sortedData);
            setOrderSummary("agreed_asc")
        } else {
            sortedData.sort((a, b) => b.approved_reduction - a.approved_reduction);
            setDataTable(sortedData);
            setOrderSummary("agreed_desc")
        }
    }

    /**
     * @function
     * @name handleOrderByDisagreed
     * @description Order the data in table by unapproved_reduction
     * @param orden - Order specified
    */
    const handleOrderByDisagreed = (orden) => {
        const sortedData = [...dataTable];
        if (orden === "asc") {
            sortedData.sort((a, b) => a.unapproved_reduction - b.unapproved_reduction);
            setDataTable(sortedData);
            setOrderSummary("disagreed_asc")
        } else {
            sortedData.sort((a, b) => b.unapproved_reduction - a.unapproved_reduction);
            setDataTable(sortedData);
            setOrderSummary("disagreed_desc")
        }
    }

    /**
     * @function
     * @name restablecerSummary
     * @description Reestablish the order of the data in the table
    */
    function restoredSummary() {
        setDataTable(dataOriginal)
        setOrderSummary("")
    }

  return (
    <div className='flex flex-col rounded-xl w-full bg-neutral-background gap-y-1'>
      <div className='rounded-t-xl flex flex-row justify-center w-full items-center bg-neutral-200 h-0.50/5'>
        <p className='mb-0 font-semibold 4xl:text-xl'>{t("sumamry.plantDetail")}</p>
      </div>
      {/* Space of the filter input and button to reestablish the order */}
      <div className="w-full flex flex-row justify-between px-1 h-0.50/5 items-center">
        <div className="relative w-4.75/5 flex items-center">
            <SearchIcon className='left-3 absolute' />
            <input onChange={handleFilterChange} type="text" className="py-2 ps-10 pe-2 rounded-xl w-full border-2 border-solid focus:outline-none focus:border-focused" placeholder={t("sumamry.placeholder")} />
        </div>
        <div className='flex justify-center w-0.25/5'>
            <button onClick={restoredSummary}  className='flex justify-center items-center bg-brand-300 hover:bg-brand-200 h-10 w-10 border-none rounded-full'>
                <ReplayIcon />
            </button>
        </div>
      </div>
      {/* Space of the table */}
      <div className='px-1 my-1 h-4/5 2xl:h-4.25/5 w-full overflow-y-auto'>
        <table className='w-full'>
            <thead className='bg-neutral-background z-50 sticky top-0'>
                <tr>
                    <th>
                        <div className='flex flex-col justify-center my-1'>
                            <div className='flex flex-row justify-center'>
                                <div className='flex items-center'>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-base 4xl:text-lg font-semibold">{t("sumamry.plant")}</p>
                                </div>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div className='flex flex-col justify-center my-1'>
                            <div className='flex flex-row justify-center xl:gap-1'>
                                <div className='flex items-center'>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-base 4xl:text-lg font-semibold">{t("sumamry.onHand")}</p>
                                </div>
                                <div className='flex items-center h-full'>
                                    {orderSummary === "" || (orderSummary !== "hand_asc" && orderSummary !== "hand_desc")
                                    ? (<IconButton className='!p-0' onClick={() => handleOrderByHand("asc")}><ArrowCircleUpIcon className='w-full h-full' /></IconButton>):null}
                                    {orderSummary === "hand_asc" ? (<IconButton className='!p-0' onClick={() => handleOrderByHand("desc")}><ArrowCircleUpTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                    {orderSummary === "hand_desc" ? (<IconButton className='!p-0' onClick={() => handleOrderByHand("asc")}><ArrowCircleDownTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                </div>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div className='flex flex-col justify-center my-1'>
                            <div className='flex flex-row justify-center xl:gap-1'>
                                <div className='flex items-center'>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-base 4xl:text-lg font-semibold">{t("sumamry.potentialReduction")}</p>
                                </div>
                                <div className='flex items-center h-full'>
                                    {orderSummary === "" || (orderSummary !== "potential_asc" && orderSummary !== "potential_desc")
                                    ? (<IconButton className='!p-0' onClick={() => handleOrderByPotential("asc")}><ArrowCircleUpIcon /></IconButton>):null}
                                    {orderSummary === "potential_asc" ? (<IconButton className='!p-0' onClick={() => handleOrderByPotential("desc")}><ArrowCircleUpTwoToneIcon /></IconButton>):null}
                                    {orderSummary === "potential_desc" ? (<IconButton className='!p-0' onClick={() => handleOrderByPotential("asc")}><ArrowCircleDownTwoToneIcon /></IconButton>):null}
                                </div>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div className='flex flex-col justify-center my-1'>
                            <div className='flex flex-row justify-center xl:gap-1'>
                                <div className='flex items-center'>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-base 4xl:text-lg font-semibold">{t("sumamry.materialRisk")}</p>
                                </div>
                                <div className='flex items-center h-full'>
                                    {orderSummary === "" || (orderSummary !== "risk_asc" && orderSummary !== "risk_desc")
                                    ? (<IconButton className='!p-0' onClick={() => handleOrderByRisk("asc")}><ArrowCircleUpIcon /></IconButton>):null}
                                    {orderSummary === "risk_asc" ? (<IconButton className='!p-0' onClick={() => handleOrderByRisk("desc")}><ArrowCircleUpTwoToneIcon /></IconButton>):null}
                                    {orderSummary === "risk_desc" ? (<IconButton className='!p-0' onClick={() => handleOrderByRisk("asc")}><ArrowCircleDownTwoToneIcon /></IconButton>):null}
                                </div>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div className='flex flex-col justify-center my-1'>
                            <div className='flex flex-row justify-center xl:gap-1'>
                                <div className='flex items-center'>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-base 4xl:text-lg font-semibold">{t("sumamry.materialOverstock")}</p>
                                </div>
                                <div className='flex items-center h-full'>
                                    {orderSummary === "" || (orderSummary !== "overstock_asc" && orderSummary !== "overstock_desc")
                                    ? (<IconButton className='!p-0' onClick={() => handleOrderByOverstock("asc")}><ArrowCircleUpIcon /></IconButton>):null}
                                    {orderSummary === "overstock_asc" ? (<IconButton className='!p-0' onClick={() => handleOrderByOverstock("desc")}><ArrowCircleUpTwoToneIcon /></IconButton>):null}
                                    {orderSummary === "overstock_desc" ? (<IconButton className='!p-0' onClick={() => handleOrderByOverstock("asc")}><ArrowCircleDownTwoToneIcon /></IconButton>):null}
                                </div>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div className='flex flex-col justify-center my-1'>
                            <div className='flex flex-row justify-center xl:gap-1'>
                                <div className='flex items-center'>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-base 4xl:text-lg font-semibold">{t("sumamry.approved")}</p>
                                </div>
                                <div className='flex items-center h-full'>
                                    {orderSummary === "" || (orderSummary !== "agreed_asc" && orderSummary !== "agreed_desc")
                                    ? (<IconButton className='!p-0' onClick={() => handleOrderByAgreed("asc")}><ArrowCircleUpIcon /></IconButton>):null}
                                    {orderSummary === "agreed_asc" ? (<IconButton className='!p-0' onClick={() => handleOrderByAgreed("desc")}><ArrowCircleUpTwoToneIcon /></IconButton>):null}
                                    {orderSummary === "agreed_desc" ? (<IconButton className='!p-0' onClick={() => handleOrderByAgreed("asc")}><ArrowCircleDownTwoToneIcon /></IconButton>):null}
                                </div>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div className='flex flex-col justify-center my-1'>
                            <div className='flex flex-row justify-center xl:gap-1'>
                                <div className='flex items-center'>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-base 4xl:text-lg font-semibold">{t("sumamry.unApproved")}</p>
                                </div>
                                <div className='flex items-center h-full'>
                                    {orderSummary === "" || (orderSummary !== "disagreed_asc" && orderSummary !== "disagreed_desc")
                                    ? (<IconButton className='!p-0' onClick={() => handleOrderByDisagreed("asc")}><ArrowCircleUpIcon /></IconButton>):null}
                                    {orderSummary === "disagreed_asc" ? (<IconButton className='!p-0' onClick={() => handleOrderByDisagreed("desc")}><ArrowCircleUpTwoToneIcon /></IconButton>):null}
                                    {orderSummary === "disagreed_desc" ? (<IconButton className='!p-0' onClick={() => handleOrderByDisagreed("asc")}><ArrowCircleDownTwoToneIcon /></IconButton>):null}
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {filteredData.map((item, index) => (
                    <tr key={item.plant} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                        <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{item.plant}</p></td>
                        <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{formatCurrency(item.on_hand, "standard", currency, locale, changeRateSelected)}</p></td>
                        <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{formatCurrency(item.potential_reduction, "standard", currency, locale, changeRateSelected)}</p></td>
                        <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{formatNewNumber(item.on_risk_materials, "standard")}</p></td>
                        <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{formatNewNumber(item.overstocked_materials, "standard")}</p></td>
                        <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{formatCurrency(item.approved_reduction, "standard", currency, locale, changeRateSelected)}</p></td>
                        <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{formatCurrency(item.unapproved_reduction, "standard", currency, locale, changeRateSelected)}</p></td>
                    </tr>
                ))}
            </tbody>
        </table>
      </div>
    </div>
  )
}

TableSummary.propTypes = {
    summaryData: PropTypes.array
}

export default TableSummary
