// Import the resources
import React, {useState, useEffect, useContext} from 'react';
import Cookies from 'js-cookie';
import { useMsal } from '@azure/msal-react';
import '../../styles/initial_style.css';
import ModalInitial from '../../components/pure/modals/modalInitial';
import { postLogin, getToken, getZones } from '../../models/login';
import Loading from '../../components/pure/loading';
import Swal from 'sweetalert2';
import { CountriesContext } from '../../context/CountriesContext';
import { PermissionContext } from '../../context/PermissionContext';
import { ZoneContext } from '../../context/ZoneContext';
import { RoleContext } from '../../context/RoleContext';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetStateSummary } from '../../redux/summarySlice';
import { resetStatePolicy } from '../../redux/policySlice';
import { resetStateSales } from '../../redux/saleSlice';
import { resetStateSpend } from '../../redux/spendSlice';
import { resetStateMaterial } from '../../redux/materialSlice';
import { resetStateUser } from '../../redux/userSlice';
import { resetStateAnomalies } from '../../redux/anomalySlice';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';
import IPALogo from '../../images/IPALogo.svg'
import signo from '../../images/Vector.png'
import name from '../../images/nameNewLogin.png'
import { removeAllCookies } from '../../models/generics';

/**
 * @component
 * @description This component show the app's initial page.
*/
const InitialPage = () => {

    const { instance } = useMsal();

    const { t } = useTranslation();

    const dispatch = useDispatch()

    /**
     * @const
     * @description Const to save the text to show while the view is loading
    */
    const [loadText, setLoadText] = useState(t("cargaTextCountry"));

    /**
     * @const
     * @description Const to save the text to show while the view is loading
    */
    const [makeLogin, setMakeLogin] = useState(false);

    /**
     * @const
     * @description Const to save the text to show while the view is loading
    */
    const [expirationDate, setExpirationDate] = useState(null);

    /**
     * @context
     * @description Access to update a global state for CountriesContext
    */
    const {setCountries} = useContext(CountriesContext);

    /**
     * @context
     * @description Access to update a global state for PermissionContext
    */
    const {setPermissions} = useContext(PermissionContext);

    /**
     * @context
     * @description Access to update a global state for RoleContext
    */
    const {setRole} = useContext(RoleContext);

    /**
     * @context
     * @description Access to update a global state for ZoneContext
    */
    const {setZone} = useContext(ZoneContext);

    const [zoneSelect, setZoneSelect] = useState([]);

    /**
     * @context
     * @description Access to update a global state for UserContext
    */
    const {setUser} = useContext(UserContext);
    
    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to show the logoCarga
    */
    const [loading, setLoading] = useState(false);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local state to show modal initial to select a country according to the user
    */
    const [initialModal, setInitialModal] = useState(false);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local state to show modal initial to select a country according to the user
    */
    const [modalZone, setModalZone] = useState(false);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local state to know the countries quantity based on the endpoint response
    */
    const [quantityCountries, setQuantityCountries] = useState(0);

    const getZonesByUser = (token, email) => {
        setLoading(true)
        getZones().then((res) => {
            setLoading(false)
            if (res.length > 1) {
                setZoneSelect(res)
                openZoneModal()
            } else {
                Cookies.set('zone_user', res[0], { expires: expirationDate, secure: true, sameSite: 'Strict' });
                setZone(res[0])
                logueo(token, email, res[0]);
            }
        }).catch((error) => {
            setZoneSelect([])
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("unauthorizedUserTitle"),
                    text: t("unauthorizedUserDesc"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "Ok",
                    allowOutsideClick: false
                }).then(() => {
                    removeAllCookies()
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        })
    }

    async function fetchAndLogIn(userAccount) {
        setLoading(true)
        setLoadText(t("cargaTextZones"))
        try {
            const res = await getToken(userAccount);
            const expireDate = new Date(res.expiresOn)
            setExpirationDate(expireDate)
            const token = res.tokenType+ " " + res.idToken;
            Cookies.set('email', res.account.username, { expires: expireDate });
            Cookies.set('token', token, { expires: expireDate });
            Cookies.set('accessToken', res.accessToken, { expires: expireDate });
            getZonesByUser(token, res.account.username)
        } catch (error) {
            setLoading(false)
        }
    };

    const handleLogin = () => {
        setMakeLogin(true)
        if (Cookies.get("token") !== undefined) {
            getZonesByUser(Cookies.get('token'), Cookies.get('email'))
            setMakeLogin(false)
        } else {
            instance.loginPopup().then(response => {
                fetchAndLogIn(response.account)
                setMakeLogin(false)
            }).catch(error => {
                setMakeLogin(false)
            });
        }
    }

    /**
     * @function
     * @name openInitialModal
     * @description Function to update the value of the login state and modal state
    */
    function openInitialModal() {
        setInitialModal(!initialModal);
    }

    /**
     * @function
     * @name openZoneModal
     * @description Function to update the value of the login state and modal state
    */
    function openZoneModal() {
        setModalZone(!modalZone);
    }

    /**
     * @function
     * @name logueo
     * @description Function to execute the login endpoint with the email and password from the login modal
    */
    function logueo(token, email, zone) {
        setLoadText(t("cargaTextCountry"))
        setLoading(true)
        setMakeLogin(true)
        const data = {
            "email": email
        }
        postLogin(data, token, zone).then((response) => {
            const countriesPlants = response.data.countries_with_plants.filter((item) => item.plants.length > 0)
            if (countriesPlants.length > 0) {
                setQuantityCountries(countriesPlants.length)
                setCountries(countriesPlants)
                setPermissions(response.data.permissions)
                setRole(response.data.role)
                setUser(email)
                setLoading(false)
                openInitialModal()
                Cookies.set('permissions', response.data.permissions, { expires: expirationDate, secure: true, sameSite: 'Strict' });
                Cookies.set('role', response.data.role, { expires: expirationDate, secure: true, sameSite: 'Strict' });
            } else {
                setLoading(false)
                Swal.fire({
                    icon: "error",
                    html:`<p class="mb-0 font-semibold text-neutral-300">${t("emptyText")}</p>`,
                    title: `${t("emptyTitle")}`,
                    showConfirmButton: true,
                    confirmButtonColor: '#FFCF26',
                    allowOutsideClick: false,
                }).then(() => {
                    setModalZone(true);
                })
            }
            setMakeLogin(false)
        }).catch((error)=> {
            setMakeLogin(false)
            Cookies.remove('email');
            Cookies.remove('token');
            Cookies.remove('country_user');
            Cookies.remove('permissions');
            Cookies.remove('plants_user');
            Cookies.remove('role');
            Cookies.remove('zone_user');
            Cookies.remove('accessToken');
            if (error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    html:`
                    <p class="mb-0 font-semibold text-neutral-300">${t("firtLoginFail")} <a class="underline text-blue-500">luis.suarez@gmodelo.com.mx</a> ${t("secondLoginFail")}</p>
                    `,
                    title: `${t("loginTitle")}`,
                    showConfirmButton: true,
                    confirmButtonColor: '#FFCF26',
                    confirmButtonText: 'Close'
                });
            } else {
                Swal.fire({
                    icon: "error",
                    html:`<p class="mb-0 font-semibold text-neutral-300">${t("initialFail")}</p>`,
                    title: `${t("initialTitle")}`,
                    showConfirmButton: true,
                    confirmButtonColor: '#FFCF26',
                    confirmButtonText: 'Close'
                });
            }
            setLoading(false)
        })
    }

    const handleSelectCountry = (value) => {
        Cookies.set('zone_user', value, { expires: expirationDate, secure: true, sameSite: 'Strict' });
        setZone(value)
        openZoneModal()
        logueo(Cookies.get('token'), Cookies.get('email'), value);
    }

    useEffect(() => {
        dispatch(resetStateSummary());
        dispatch(resetStatePolicy());
        dispatch(resetStateSales());
        dispatch(resetStateSpend());
        dispatch(resetStateMaterial());
        dispatch(resetStateUser())
        dispatch(resetStateAnomalies())
    }, []);

    return (
        <div className='flex flex-col justify-center items-center h-full w-full image-wallpaper'>
            {/* Zone Modal */}
            <Dialog open={modalZone} classes={{ paper: "!max-w-full !w-1/2 !rounded-lg" }}>
                <DialogTitle className='bg-brand-300'>
                    <p className='mb-0 font-bold text-center'>Select your zone</p>
                </DialogTitle>
                <DialogContent className='bg-neutral-400 !py-2 !px-2'>
                    <div className='w-full flex flex-row justify-between gap-2'>
                        {zoneSelect.map((item, index) => (
                            <button key={index + 1} onClick={() => handleSelectCountry(item)} className='rounded font-semibold py-2 w-1/2 border-none bg-neutral-300 hover:bg-neutral-200'>{item}</button>
                        ))}
                    </div>
                </DialogContent>
            </Dialog>
            {/* Initial Modal */}
            <Dialog open={initialModal} classes={{ paper: "dialog-important" }} style={{ "--quantity-countries": quantityCountries }}>
                <DialogTitle className='bg-brand-300'>
                    <p className='mb-0 font-bold text-center text-xs 2xl:text-sm 4xl:text-base'>{t("country")}</p>
                </DialogTitle>
                <DialogContent className='bg-neutral-400 !py-2 !px-2'>
                    <ModalInitial expirationDate={expirationDate} />
                </DialogContent>
            </Dialog>
            {/* Space to show logoCarga if the local state "loading" is true */}
            {loading ? (
                <Loading text={loadText} />
            ): null}
            <div className='flex flex-row justify-center w-full py-10'>
                <img src={signo} className='w-0.50/5' alt='signo-abi'/>
            </div>
            <div className='flex flex-row justify-center w-full py-10'>
                <div className='flex flex-col w-0.50/5'>
                    <button disabled={makeLogin} onClick={() => handleLogin()} className='rounded font-semibold py-1 border w-full border-black bg-brand-300 hover:bg-brand-200 duration-300 disabled:bg-neutral-200'>{t("accessButton")} <ForwardIcon /></button>
                    {makeLogin && (<p className='m-0 text-neutral-200 italic text-sm 4xl:text-base text-center'>{t("waitingText")}</p>)}
                </div>
            </div>
            <div className='flex flex-row justify-center w-full py-4'>
                <img src={IPALogo} className='w-0.50/5' alt='signo-abi'/>
            </div>
            <div className='flex flex-row justify-center w-full py-10'>
                <img src={name} className='w-0.50/5' alt='signo-abi'/>
            </div>
        </div>
    );
}

export default InitialPage;
