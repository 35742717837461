import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

function postAllAnomalies() {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/anomalies/?${apiParam}`, {}, config).then(res => {
      const responseData = res.data;
      return responseData;
    });
}

function updateAnomaly(args) {
  const config = {
      headers: {
        'Authorization': Cookies.get('token')
      }
  }
  return axios.put(`${domain}/ipa_app/anomalies/?${apiParam}`, args, config).then(res => {
    const responseData = res.data;
    return responseData;
  });
}

function postAllWithDateRange(args) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/anomalies/?${apiParam}`, args, config).then(res => {
      const responseData = res.data;
      return responseData;
    });
}

function postAllWithMonths(args) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/anomalies/?${apiParam}`, args, config).then(res => {
      const responseData = res.data;
      return responseData;
    });
}

export { postAllAnomalies, updateAnomaly, postAllWithDateRange, postAllWithMonths }