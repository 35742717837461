import React, {useState, useEffect, useContext} from 'react'
import '../../../styles/block_style.css'
import { useNavigate } from 'react-router-dom';
import TableBlock from '../../pure/tableBlock';
import ModalBlock from '../../pure/modals/modalBlock';
import Loading from '../../pure/loading';
import Papa from 'papaparse';
import { PlantsContext } from '../../../context/PlantsContext';
import { CountryComponentContext } from '../../../context/CountryComponentContext';
import { ZoneContext } from '../../../context/ZoneContext';
import { PermissionContext } from '../../../context/PermissionContext';
import { useTranslation } from 'react-i18next';
import { getBlockInitial, getBlockContinue, postBlock, postApproveBlock } from '../../../models/block';
import { getPlannersPlants } from '../../../models/policy_data';
import { addPlanners, addMaterials, addBlockData, addPlannersSelected, addPlantsSelected, addPage, addMethodUsed, addValidateLoad, addPlannersModal, addPlantsModal, addMaterialTypesModal } from '../../../redux/blockSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { removeAllCookies } from '../../../models/generics';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';

const Block = () => {

    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    /**
     * @hook
     * @name dispatch
     * @type {function}
     * @description It provides the ability save and get data in the redux store
    */
    const dispatch = useDispatch()

    /**
     * @hook
     * @name policy
     * @type {function}
     * @description It provides the global data in the redux store
    */
    const block = useSelector((state) => state.block)

    /**
     * @context
     * @description Access to get the global state of the PlantsContext
    */
    const {plants} = useContext(PlantsContext)

    /**
     * @context
     * @description Access to get the global state of the CountryComponentContext
    */
    const {country} = useContext(CountryComponentContext)

    /**
     * @context
     * @description Access to get the global state of the ZoneContext
    */
    const {zone} = useContext(ZoneContext)

    /**
     * @context
     * @description Access to get the global state of the PermissionContext
    */
    const {permissions} = useContext(PermissionContext)

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to show the logoCarga
    */
    const [loading, setLoading] = useState(true);

    /**
     * @const
     * @description Const to save the text to show while the view is loading
    */
    const [loadText, setLoadText] = useState(t("block.cargaText"));

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local state to save the array plants according with the user
    */
    const [userPlants, setUserPlants] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the planners obtained of the endpoint
    */
    const [planners, setPlanners] = useState([]);

    const [plannersFilter, setPlannersFilter] = useState([]);
    const [plantsFilter, setPlantsFilter] = useState([]);
    const [materialSelected, setMaterialSelected] = useState([]);

    const [paddingPlanners, setPaddingPlanners] = useState(0);
    const [paddingPlants, setPaddingPlants] = useState(0);
    const [gridPlanners, setGridPlanners] = useState(0);
    const [gridPlants, setGridPlants] = useState(0);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the material types from the endpoint
    */
    const [materialTypes, setMaterialTypes] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the block data obtained of the endpoint
    */
    const [blockData, setBlockData] = useState([]);
    const [blocksApprove, setBlocksApprove] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local States to save the Planners and Plants used in the filter on the superior bar
    */
    const [plannersSelected, setPlannersSelected] = useState("");
    const [plantsSelected, setPlantsSelected] = useState("");

    const [possiblePlants, setPossiblePlants] = useState([]);
    const [buttonsArmed, setButtonsArmed] = useState([]);

    const [filteredData, setFilteredData] = useState([]);

    const [page, setPage] = useState(1);

    const [methodUsed, setMethodUsed] = useState("");

    const [validateLoad, setValidateLoad] = useState(true);

    const [loadingPage, setLoadingPage] = useState(false);

    const [restartScroll, setRestartScroll] = useState(false);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local state to show modal to make the POST method
    */
    const [modal, setModal] = useState(false);

    const [allowUse, setAllowUse] = useState(true);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local states to save Planners, Plants, Turnover and Material Types selected in the modal
    */
    const [plannersModal, setPlannersModal] = useState([]);
    const [plantsModal, setPlantsModal] = useState([]);
    const [materialTypesModal, setMaterialTypesModal] = useState([]);

    /**
     * @hook
     * @name navigate
     * @type {function}
     * @description It provides the ability to the app with the country selected
    */
    const navigate = useNavigate()

    async function searchBlockData(planners, materials) {
        try {
            const tempBlock = await getBlockInitial(country, page, zone)
            if (tempBlock.length < 200) {
                dispatch(addValidateLoad(false))
            }
            dispatch(addPlanners(planners))
            dispatch(addMaterials(materials))
            if (tempBlock.length > 0) {
                dispatch(addBlockData(tempBlock))
                dispatch(addPlannersSelected(tempBlock[0].planner))
                dispatch(addPlantsSelected(tempBlock[0].plant))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("block.emptyData")
                })
            }
            
            setLoading(false)
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: '¡Oops!',
                confirmButtonColor: '#FFC629',
                text: t("ApiFail")
            })
            setLoading(false)
        }
    }

    function organizeData(data) {
        const filteredBlockData = data.filter(item => {
            const matchingItemInBlock = block.blockData.find(blockItem => blockItem.id === item.id);
            return!matchingItemInBlock || item.approved!== matchingItemInBlock.approved;
        });

        return filteredBlockData
    }

    useEffect(() => {
        setUserPlants(plants)
    }, []);

    useEffect(() => {
        const plannersArray= [...new Set(block.blockData.map(item => item.planner))];
        const plantsArray = [...new Set(block.blockData.map(item => item.plant))];
        const materialsArray = [...new Set(block.blockData.map(item => item.material_type))];
        if (blockData.length > 0) {
            const arrayUpdated = block.blockData.map(objeto1 => {
                const respectiveObject = blockData.find(objeto2 => objeto2.id === objeto1.id);
                return {
                 ...objeto1,
                  approved: respectiveObject? respectiveObject.approved : objeto1.approved
                };
            });
            setBlockData(arrayUpdated)
        } else {
            setBlockData(block.blockData)
        }
        setPlannersFilter(plannersArray)
        setPlantsFilter(plantsArray)
        setMaterialSelected(materialsArray)
        if (block.blockData.length > 0) {
            setLoading(false)
        }
    }, [block.blockData]);

    useEffect(() => {
        setPlanners(block.planners)
        setMaterialTypes(block.materials)
    }, [block.planners, block.materials]);

    useEffect(() => {
        setPlannersSelected(block.plannersSelected)
        setPlantsSelected(block.plantsSelected)
    }, [block.plannersSelected, block.plantsSelected]);

    useEffect(() => {
        setMethodUsed(block.methodUsed)
    }, [block.methodUsed]);

    useEffect(() => {
        setPage(block.page)
    }, [block.page]);

    useEffect(() => {
        setValidateLoad(block.validateLoad)
    }, [block.validateLoad]);

    useEffect(() => {
        setPlannersModal(block.plannersModal)
        setPlantsModal(block.plantsModal)
        setMaterialTypesModal(block.materialTypesModal)
    }, [block.plannersModal, block.plantsModal, block.materialTypesModal]);

    useEffect(() => {
        if (block.blockData.length < 1) {
            dispatch(addMethodUsed("GET"))
            getPlannersPlants(country, zone).then((response) => {
                searchBlockData(response.planners, response.materials)
            }).catch(error => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        removeAllCookies()
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        confirmButtonColor: '#FFC629',
                        text: t("ApiFail")
                    })
                }
                setLoading(false)
            });
        }
    }, []);

    useEffect(() => {
        let filtered
        if (plantsFilter.length > 1 || plannersFilter.length > 1) {
            filtered = [...blockData].sort((a, b) => {
                if(a.plant === plantsSelected && a.planner === plannersSelected) {
                    return -1;
                } else if(b.plant === plantsSelected && b.planner === plannersSelected) {
                    return 1;
                } else {
                    return 0;
                }
            });
        } else {
            filtered = blockData
        }
        setFilteredData(filtered);
    }, [plannersSelected, plantsSelected, blockData, plantsFilter, plannersFilter]);

    useEffect(() => {
        const possible = blockData.filter(item => plannersSelected.includes(item.planner));
        const possiblePlants = [...new Set(possible.map(item => item.plant))];
        setPossiblePlants(possiblePlants)
    }, [plannersSelected, blockData]);

    useEffect(() => {
        const newArray = plantsFilter.map((item) => {
            let buttonClass = ""
            if (plantsSelected.includes(item)) {
                buttonClass = 'bg-black text-brand-300';
            } else if (possiblePlants.includes(item)) {
                buttonClass = 'bg-brand-200 text-neutral-500 hover:bg-brand-300';
            } else {
                buttonClass = 'bg-neutral-100 text-neutral-500 hover:bg-brand-200';
            }

            return {
                plant: item,
                buttonClass: buttonClass
            };
        })
        setButtonsArmed(newArray)
    }, [plantsSelected, plantsFilter, possiblePlants]);

    function plannersTableFilter(value) {
        dispatch(addPlannersSelected(value))
    }

    function plantsTableFilter(value) {
        dispatch(addPlantsSelected(value))
    }

    const sendNewPlanners = (data) => {
        dispatch(addPlannersModal(data))
    }
    const sendNewPlants = (data) => {
        dispatch(addPlantsModal(data))
    }
    const sendMaterialsType = (data) => {
        dispatch(addMaterialTypesModal(data))
    }

    const sendPage = (value) => {
        if (validateLoad !== false) {
            dispatch(addPage(page + value))
        }

        if (validateLoad && methodUsed === "GET") {
            setLoadingPage(true)
            setAllowUse(false)
            getBlockContinue(plantsFilter[0].toString(), plannersFilter[0].toString(), materialSelected[0].toString(), (page + value), zone).then((response) => {
                dispatch(addBlockData([...block.blockData, ...response]))
                if (response.length < 200) {
                    dispatch(addValidateLoad(false))
                }
                setLoadingPage(false)
                setAllowUse(true)
            }).catch(error => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        removeAllCookies()
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        confirmButtonColor: '#FFC629',
                        text: t("ApiFail")
                    })
                }
                setLoadingPage(false)
                setAllowUse(true)
            });
        } else if (validateLoad && methodUsed === "POST") {
            const filter = {
                "planners": plannersModal,
                "plants": plantsModal,
                "material_type": materialTypesModal
            }
            setLoadingPage(true)
            setAllowUse(false)
            postBlock((page + value), filter, zone).then((response) => {
                dispatch(addBlockData([...block.blockData, ...response.data]))
                if (response.data.length < 200) {
                    dispatch(addValidateLoad(false))
                }
                setLoadingPage(false)
                setAllowUse(true)
            }).catch(error => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        removeAllCookies()
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        confirmButtonColor: '#FFC629',
                        text: t("ApiFail")
                    })
                }
                setLoadingPage(false)
                setAllowUse(true)
            });
        }
    }

    function updateViewData() {
        setLoading(true)
        const filter = {
            "planners": plannersModal,
            "plants": plantsModal,
            "material_type": materialTypesModal
        }

        postBlock(1, filter, zone).then((response) => {
            if (response.data.length < 200) {
                dispatch(addValidateLoad(false))
            } else {
                dispatch(addValidateLoad(true))
            }

            if (response.data.length > 0) {
                setRestartScroll(true)
                dispatch(addBlockData(response.data))
                dispatch(addPlannersSelected(response.data[0].planner))
                dispatch(addPlantsSelected(response.data[0].plant))
                dispatch(addPage(1))
                dispatch(addMethodUsed("POST"))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("block.emptyDataPost")
                })
            }
            setLoading(false)
        }).catch(error => {
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    removeAllCookies()
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        });
        setModal(!modal)
    }

    function approveData() {
        setLoading(true)
        setLoadText(t("block.aporoveText"))
        const now = new Date();
        const arrayApprove = blocksApprove.map(item => ({
            marc_id: item.id,
            approved: item.approved,
            approved_date: now
        }))
        postApproveBlock(arrayApprove, zone).then((response) => {
            dispatch(addBlockData(blockData))
            setBlocksApprove([])
            Swal.fire({
                icon: 'success',
                title: t("policyManagement.correctApproveTitle"),
                text: t("policyManagement.correctApproveText"),
                confirmButtonColor: '#FFC629',
                confirmButtonText: "OK",
            })
        }).catch(error => {
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    removeAllCookies()
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        });
    }

    function exportToCSV(data, filename) {
        const csv = Papa.unparse(data);
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const a = document.createElement('a');
        a.href = csvUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    function handleExportClick() {
        const fileName = 'data_block_unblock.csv';
        exportToCSV(filteredData, fileName);
    }

    useEffect(() => {
        if (plannersFilter.length > 12) {
            setPaddingPlanners(1)
            setGridPlanners(12) 
        } else {
            setPaddingPlanners(0)
            setGridPlanners(plannersFilter.length)
        }

        if (plantsFilter.length > 12) {
            setPaddingPlants(1)
            setGridPlants(12) 
        } else {
            setPaddingPlants(0)
            setGridPlants(plantsFilter.length)
        }
        
    }, [plannersFilter, plantsFilter]);

    const gridStylePlanner = {
        gridTemplateColumns: `repeat(${gridPlanners}, minmax(0, 1fr))`
    };

    const gridStylePlant = {
        gridTemplateColumns: `repeat(${gridPlants}, minmax(0, 1fr))`
    };

    const sendRestarScroll = (data) => {
        setRestartScroll(data)
    }

    const sendChecked = (data) => {
        const newArray = blockData.map((originalItem) => {
            if (originalItem.id === data.id) {
                return { ...originalItem, ...data }
            }
            return originalItem
        })
        setBlockData(newArray)

        const filteredBlockData = organizeData(newArray)
        setBlocksApprove(filteredBlockData)
    }

    const sendChekeds = (data) => {
        const updatedArray = blockData.map(item1 => {
            const matchingItem = data.find(item2 => item2.id === item1.id);
            if (matchingItem) {
              return matchingItem;
            }
            return item1;
        });
        setBlockData(updatedArray)

        const filteredBlockData = organizeData(updatedArray)
        setBlocksApprove(filteredBlockData)
    }

  return (
    <div className='wallpaper flex flex-col w-4.25/5 xl:w-4.5/5 h-full p-1 gap-y-1'>
        <Dialog open={modal} classes={{paper: "!max-w-4.25/5 !w-4.25/5 2xl:!max-w-1/2 2xl:!w-1/2 4xl:!max-w-2/5 4xl:!w-2/5 !h-4/5 2xl:!h-1.5/2 4xl:!h-1.75/5 modal-block-font !rounded-lg"}}>
            <DialogTitle className='flex justify-between w-full items-center !py-1'>
                <div className='flex justify-start w-1/2'>
                    <p className='mb-0 font-semibold text-neutral-200'>{t("policyManagement.filterSelection")}</p>
                </div>
                <div className='flex justify-center w-0.25/5'>
                    <IconButton color="inherit" onClick={() => setModal(!modal)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent className='!p-2 !overflow-x-hidden'>
                <ModalBlock planners={planners} 
                    materialTypes={materialTypes} 
                    plants={userPlants} 
                    plannersSelected={plannersFilter} 
                    plantsSelected={plantsFilter} 
                    materialSelected={materialSelected} 
                    sendNewPlanners={sendNewPlanners}
                    sendNewPlants={sendNewPlants}
                    sendMaterialsType={sendMaterialsType}
                />
            </DialogContent>
            <DialogActions className='p-2'>
                <button disabled={plannersModal.length < 1 || plantsModal.length < 1 || materialTypesModal.length < 1} onClick={updateViewData} className='border-none bg-brand-300 hover:bg-brand-200 disabled:bg-transparent disabled:border-neutral-200 disabled:border disabled:border-solid disabled:text-neutral-200 w-0.75/5 rounded font-semibold py-2'>{t("applyButton")}</button>
            </DialogActions>
        </Dialog>
        {/* Space to show logoCarga if the local state "cargando" is true */}
        {loading ? (
            <Loading text={loadText} />
        ): null}
        <div className="flex items-center flex-row gap-3 2xl:gap-5 px-2 bg-neutral-500 rounded-xl 4xl:h-0.25/5 h-0.50/5">
            {/* Yellow button filter */}
            <div className='flex items-center w-0.50/5'>
                <button disabled={!allowUse} onClick={() => setModal(!modal)} className="w-full h-full border-none rounded font-semibold py-2 bg-brand-200 hover:bg-brand-300 disabled:bg-neutral-500 disabled:text-neutral-300 disabled:border disabled:border-solid disabled:border-neutral-300">{t("policyManagement.filterButton")}</button>
            </div>

            {/* Planners selected row */}
            <div className={`${plannersFilter.length < 1 ? "hidden" : "flex flex-row"} max-w-2.25/5 max-h-10 gap-2`}>
                <div className="content-center">
                    <h6 className="mb-0 text-neutral-200 text-xs xl:text-base font-semibold">{t("policyManagement.planners")}</h6>
                </div>
                <div style={gridStylePlanner} className={`grid gap-1 max-w-full h-9 pe-${paddingPlanners} items-center overflow-y-auto overflow-x-hidden planner_row_block`}>
                    {plannersFilter.map((item, index) => (
                        <button key={item} onClick={() => plannersTableFilter(item)} disabled={plannersSelected === item}
                        className={`flex items-center justify-center text-center w-8 xl:w-10 h-7 xl:h-8 rounded ${plannersSelected === item ? 'bg-black text-brand-300' : 'bg-neutral-100 text-neutral-500 hover:bg-brand-200'}`}>
                            <p className='mb-0 text-xs xl:text-base'>{item}</p>
                        </button>
                    ))}
                </div>
            </div>
            
            {/* Plants selected row */}
            <div className={`${buttonsArmed.length < 1 ? "hidden" : "flex flex-row"} items-center max-w-2.25/5 max-h-10 gap-2`}>
                <div className="content-center">
                    <h6 className="mb-0 text-neutral-200 text-xs xl:text-base font-semibold">{t("policyManagement.plants")}</h6>
                </div>
                <div style={gridStylePlant} className={`grid gap-1 max-w-full h-9 pe-${paddingPlants} items-center overflow-y-auto overflow-x-hidden planner_row_block`}>
                    {buttonsArmed.map((item) => (
                        <button key={item.plant} onClick={() => plantsTableFilter(item.plant)}
                        className={`flex items-center justify-center text-center w-10 xl:w-14 h-7 xl:h-8 rounded ${item.buttonClass}`}>
                            <p className='mb-0 text-xs xl:text-base'>{item.plant}</p>
                        </button>
                    ))}
                </div>
            </div>
        </div>
        <div className='flex flex-col w-full 4xl:h-4.75/5 h-4.5/5 gap-y-2 rounded-lg p-2' style={{backgroundColor:"#F0F0F0"}}>
            <div className='w-full h-4.75/5'>
                <TableBlock data={filteredData} 
                loadingPage={loadingPage} 
                methodUsed={methodUsed} 
                restartScroll={restartScroll} 
                sendPage={sendPage} 
                sendRestarScroll={sendRestarScroll}
                sendChecked={sendChecked}
                sendChekeds={sendChekeds}
                 />
            </div>
            <div className='items-center w-full h-0.25/5 flex flex-row justify-end gap-1'>
                <button onClick={handleExportClick} className='flex justify-center items-center text-xs xl:text-sm 2xl:text-base w-0.75/5 h-full border-none rounded font-semibold px-2 bg-brand-200 hover:bg-brand-300'>{t("downloadButton")}</button>
                {permissions.some((item) => item === "block") ? (
                    <button disabled={blocksApprove.length < 1 || !allowUse} onClick={approveData} className='flex justify-center items-center text-xs xl:text-sm 2xl:text-base w-0.75/5 h-full border-none rounded font-semibold px-2 bg-brand-200 hover:bg-brand-300 disabled:bg-neutral-200'>{t("block.approveButton")}</button>
                ) : null}
            </div>
        </div>
    </div>
  )
}

export default Block