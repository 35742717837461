// Import the resources
import React, {useState, useEffect, useContext}  from 'react'
import '../../styles/negotiation_style.css'
import { useTranslation } from 'react-i18next';
import { ComposedChart, Bar, YAxis, XAxis, ResponsiveContainer, LabelList, Tooltip } from 'recharts';
import { TextField } from '@mui/material';
import { formatCurrency } from '../../models/generics';
import { CurrencyContext } from '../../context/CurrencyContext';
import LabelNegotiation from './labelsCharts/labelNegotiation';
import TooltipNegoVendor from './tooltipCharts/tooltipNegoVendor';
import emptyBox from '../../images/empty.png'
import PropTypes from 'prop-types';

/**
 * @component
 * @description This component show the Pending Spend By Vendor chart in the spend tracking view
*/
const ChartPendingVendor = ({vendors}) => {

    const [orderedVendors, setOrderedVendors] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dimensions, setDimensions] = useState(0);
    const [ticks, setTicks] = useState([]);

    const {currency, locale, changeRateSelected} = useContext(CurrencyContext)

    const handleFilterChange = (event) => {
        const filteredData = [...orderedVendors].filter(item =>
            (item.vendor_name_standar).toLowerCase().includes((event.target.value).toLowerCase())
        );
        setFilteredData(filteredData)
    }

    useEffect(() => {
        const cleanedVendors = [...vendors].filter((item) => item.valor_pendiente_usd > 0)
        const orderedVendors = [...cleanedVendors].sort((a, b) => b.valor_pendiente_usd - a.valor_pendiente_usd)
        setOrderedVendors(orderedVendors)
        setFilteredData(orderedVendors)
    }, [vendors]);

    useEffect(() => {
        const ticks = filteredData.map(item => {
            let value = item.valor_pendiente_usd;
            let remainder = value % 1000;
            if (remainder >= 500) {
                value = value - remainder + 1000;
            } else {
                value = value - remainder;
            }
            return value;
        });
        setTicks([...new Set(ticks)].sort((a, b) => a - b));
    }, [filteredData]);

    useEffect(() => {
        const heightFather = document.getElementById('father').getBoundingClientRect().height;
        const heightSon = filteredData.length * 50;

        const calculateDimensions = () => {
            setDimensions(heightSon / heightFather * 100)
        }

        calculateDimensions();
        window.addEventListener('resize', calculateDimensions);

        return () => {
            window.removeEventListener('resize', calculateDimensions);
        };
    }, [filteredData, window.innerWidth]);

    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    const truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    };

    return (
        <div className='flex flex-col w-1/2 h-full bg-neutral-100 p-1 rounded-lg gap-y-2'>
            <div className='flex flex-row justify-center w-full h-0.25/5 items-center'>
                <p className='text-base 3xl:text-lg 4xl:text-xl mb-0 font-semibold'>{t("spendTracking.pendingVendor")}</p>
            </div>
            <div className='flex flex-row justify-center w-full h-0.50/5 2xl:h-0.25/5 items-center'>
                <TextField onChange={(e) => handleFilterChange(e)} 
                    sx={{width:"90%", 
                    '& label.Mui-focused': {
                        color: '#292929'
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: '#292929',
                        }
                    }}} 
                    size='small' InputProps={{className: 'rounded-lg'}} label="Filter by Vendor"
                />
            </div>
            {/* Space for the chart */}
            <div id='father' className='w-full h-4.25/5 2xl:h-4.5/5 overflow-y-auto overflow-x-hidden'>
                {orderedVendors.length > 0 ? (
                    <ResponsiveContainer width="100%" height={dimensions > 100 ? filteredData.length * 50 : "100%"}>
                        <ComposedChart layout="vertical" data={filteredData} barGap={5}>
                            <XAxis type="number" ticks={ticks} tickFormatter={(value) => formatCurrency(value, "standard", currency, locale, changeRateSelected)} className='text-xs 3xl:text-base 4xl:text-lg' />
                            <YAxis dataKey="vendor_name_standar" type="category" interval={0} className='text-xs 4xl:text-sm' tickFormatter={(value) => truncate(value, 5)} />
                            <Tooltip content={<TooltipNegoVendor />} />
                            <Bar dataKey="valor_pendiente_usd" fill="#FFC629" barSize={40}>
                                <LabelList dataKey="valor_pendiente_usd" position="right" content={LabelNegotiation} />
                            </Bar>
                        </ComposedChart>
                    </ResponsiveContainer>
                ):(
                    <div className='flex flex-col justify-center gap-y-1 h-full w-full'>
                        <div className='flex justify-center items-center w-full'>
                            <img src={emptyBox} alt='Carga Logo' className='w-1/2' />
                        </div>
                        <div className='flex flex-row justify-center'>
                            <p className='text-base 3xl:text-lg 4xl:text-xl mb-0 font-semibold'>{t("spendTracking.chartEmpty")}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

ChartPendingVendor.propTypes = {
    vendors: PropTypes.array
}

export default ChartPendingVendor
