import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Select, MenuItem } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PropTypes from 'prop-types';

const ModalPolicy = ({planners, plants, wantedMaterial, plannersSelected, plantsSelected, levelsSelected, materialTypes, materialsSelected, serviceLevelDefault, monthsMaximunStock, monthsHistory, checkAtypical, leadtime, sendMaterial, sendNewPlanners, sendNewPlants, sendNewLevels, sendMaterialsType, sendServiceLevel, sendMonthsMaximumStock, sendMonthsHistory, sendOption, sendCheck}) => {
    
    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local States to know if the checkboxes are checked
    */
    const [allPlannerSelected, setAllPlannerSelected] = useState(false);
    const [allPlantSelected, setAllPlantSelected] = useState(false);
    const [allLevelSelected, setAllLevelSelected] = useState(false);
    const [allMaterialsSelected, setAllMaterialsSelected] = useState(false);

    /**
     * @state
     * @type {string}
     * @default leadtime
     * @description Local State to save the option selected in Leadtime calculation dropdown
    */
    const [leadtimeOption, setLeadtimeOption] = useState(leadtime);

    /**
     * @function
     * @name handleOptionSelect
     * @description Function to update the selectedOption and dropdown value
     * @param {string} option - Value of the option selected
    */
    const handleLeadtimeOption = (option) => {
        setLeadtimeOption(option);
    };

    /**
     * @state
     * @type {string}
     * @default monthsHistory
     * @description Local State to save the option selected in Months of history dropdown
    */
    const [selectedHistoryOption, setSelectedHistoryOption] = useState(monthsHistory);
    
    /**
     * @function
     * @name handleOptionHistory
     * @description Function to update the selectedHistoryOption and dropdownDos value
     * @param {string} option - Value of the option selected
    */
    const handleOptionHistory = (option) => {
        setSelectedHistoryOption(option);
    };

    /**
     * @state
     * @type {string}
     * @default monthsMaximunStock
     * @description Local State to save the option selected in Months of history dropdown
    */
    const [selectedMonthsStock, setSelectedMonthsStock] = useState(monthsMaximunStock);

    /**
     * @function
     * @name handleOptionMonthsStock
     * @description Function to update the selectedMonthsStock and dropdownTres value
     * @param {string} option - Value of the option selected
    */
    const handleOptionMonthsStock = (option) => {
        setSelectedMonthsStock(option);
    };

    /**
     * @state
     * @type {string}
     * @default serviceLevelDefault
     * @description Local State to save the option selected in Service level dropdown
    */
    const [selectedServiceLevel, setSelectedServiceLevel] = useState(serviceLevelDefault);

    /**
     * @function
     * @name handleOptionServiceLevel
     * @description Function to update the selectedServiceLevel and dropdownCuatro value
     * @param {string} option - Value of the option selected
    */
    const handleOptionServiceLevel = (option) => {
        setSelectedServiceLevel(option);
    };

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to know if you have more than 12 planners to select
    */
    const [showAllPlanners, setShowAllPlanners] = useState(false);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Planners to show if you have more than 12 or less.
    */
    const [plannersToShow, setPlannersToShow] = useState([]);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to know if you have more than 12 plants to select
    */
    const [showAllPlants, setShowAllPlants] = useState(false);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Plants to show if you have more than 12 or less.
    */
    const [plantsToShow, setPlantsToShow] = useState([]);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to know if you have more than 12 material types to select
    */
    const [showAllMaterials, setShowAllMaterials] = useState(false);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Material Types to show if you have more than 12 or less.
    */
    const [materialsToShow, setMaterialsToShow] = useState([]);

    /**
     * @constants
     * @type {array}
     * @description Arrays of the options to select turnover, service level and months for maximum stock
    */
    const levels = ["0","1","2","3","4","5","6","7","8","9","10","11","12"]
    const maximumStock = [1,2,3,4,5,6]
    const serviceLevel = [0.80,0.85,0.90,0.95,0.99]

    /**
     * @state
     * @type {string}
     * @default wantedMaterial
     * @description Local State to save the material entered
    */
    const [fingeredMaterial, setFingeredMaterial] = useState(wantedMaterial);
    
    /**
     * @state
     * @type {array}
     * @default plannersSelected
     * @description Local State to save the planners selected
    */
    const [plannersOptionSelected, setPlannersOptionSelected] = useState(plannersSelected);

    /**
     * @state
     * @type {array}
     * @default plantsSelected
     * @description Local State to save the plants selected
    */
    const [plantsOptionSelected, setPlantsOptionSelected] = useState(plantsSelected);

    /**
     * @state
     * @type {array}
     * @default levelsSelected
     * @description Local State to save the turnover selected
    */
    const [levelsOptionSelected, setLevelsOptionSelected] = useState(levelsSelected);

    /**
     * @state
     * @type {array}
     * @default materialsSelected
     * @description Local State to save the material types selected
    */
    const [materialsOptionSelected, setMaterialsOptionSelected] = useState(materialsSelected);

    /**
     * @state
     * @type {boolean}
     * @default checkAtypical
     * @description Local State to save value of Exclude atypical demands check
    */
    const [atypicalDemands, setAtypicalDemands] = useState(checkAtypical);

    const [warningMaterialWrote, setWarningMaterialWrote] = useState(true);
    const [warningPlanners, setWarningPlanners] = useState(true);
    const [warningPlants, setWarningPlants] = useState(true);
    const [warningMaterialType, setWarningMaterialType] = useState(true);

    /**
     * @function
     * @name handleMaterialChange
     * @description Function to update the materialDigitado with the material entered
     * @param {string} event - The text entered
    */
    const handleMaterialChange = (event) => {
        const inputValue = event.target.value.replace(/\D/g, '').slice(0, 7);
        setFingeredMaterial(inputValue);
    }

    /**
     * @constant
     * @description The maximum length of the MaterialDigitado
    */
    const maxLength = 7

    const [plusButtonPlanners, setPlusButtonPlanners] = useState(null);
    const [lessButtonPlanners, setLessButtonPlanners] = useState(null);
    const [plusButtonPlants, setPlusButtonPlants] = useState(null);
    const [lessButtonPlants, setLessButtonPlants] = useState(null);
    const [plusButtonMaterials, setPlusButtonMaterials] = useState(null);
    const [lessButtonMaterials, setLessButtonMaterials] = useState(null);

    useEffect(() => {
        if (planners.length > 12 && showAllPlanners) {
            setPlusButtonPlanners(true)
            setLessButtonPlanners(false)
        } else if (planners.length > 12 && !showAllPlanners) {
            setPlusButtonPlanners(false)
            setLessButtonPlanners(true)
        }

        if (plants.length > 12 && showAllPlants) {
            setPlusButtonPlants(true)
            setLessButtonPlants(false)
        } else if (plants.length > 12 && !showAllPlants) {
            setPlusButtonPlants(false)
            setLessButtonPlants(true)
        }

        if (materialTypes.length > 12 && showAllMaterials) {
            setPlusButtonMaterials(true)
            setLessButtonMaterials(false)
        } else if (materialTypes.length > 12 && !showAllMaterials) {
            setPlusButtonMaterials(false)
            setLessButtonMaterials(true)
        }
    }, [planners, showAllPlanners, plants, showAllPlants, materialTypes, showAllMaterials]);

    useEffect(() => {
        if (showAllMaterials) {
            setMaterialsToShow(materialTypes)
        } else {
            setMaterialsToShow(materialTypes.slice(0, 12))
        }
        
        if (showAllPlants) {
            setPlantsToShow(plants)
        } else {
            setPlantsToShow(plants.slice(0, 12))
        }

        if (showAllPlanners) {
            setPlannersToShow(planners)
        } else {
            setPlannersToShow(planners.slice(0, 12))
        }
    }, [showAllMaterials, showAllPlants, showAllPlanners]);

    /**
     * @effect
     * @description This effect is executed when materialDigitado, plannersElegidos, plantsElegidas, levelsElegidos, 
     * materialsElegidos are changed. 
     * Send the material to the main component and the planners, plants, levels and material types if the material wrote is empty
    */
    useEffect(() => {
        sendMaterial(fingeredMaterial);
        if (fingeredMaterial !== "") {
            sendNewPlanners(["NULL"])
            sendNewPlants(["NULL"])
            sendNewLevels(["NULL"])
            sendMaterialsType(["NULL"])
        } else {
            sendNewPlanners(plannersOptionSelected)
            sendNewPlants(plantsOptionSelected)
            sendNewLevels(levelsOptionSelected)
            sendMaterialsType(materialsOptionSelected)
        }

        if (fingeredMaterial.length >= 6 && fingeredMaterial.length <= 7) {
            setWarningMaterialWrote(true)
        } else {
            setWarningMaterialWrote(false)
        }

        if (fingeredMaterial.length > 0 || plannersOptionSelected.length > 1) {
            setWarningPlanners(true)
        } else {
            setWarningPlanners(false)
        }

        if (fingeredMaterial.length > 0 || plantsOptionSelected.length > 1) {
            setWarningPlants(true)
        } else {
            setWarningPlants(false)
        }

        if (fingeredMaterial.length > 0 || materialsOptionSelected.length > 1) {
            setWarningMaterialType(true)
        } else {
            setWarningMaterialType(false)
        }
    }, [fingeredMaterial, plannersOptionSelected, plantsOptionSelected, levelsOptionSelected, materialsOptionSelected]);

    /**
     * @function
     * @name accumulatePlanners
     * @description Function to update the plannersElegidos
     * @param {string} value - Value of planner selected
    */
    function accumulatePlanners(value) {
        if (plannersOptionSelected.includes(value)) {
            if (plannersOptionSelected.length > 1) {
                setPlannersOptionSelected(plannersOptionSelected.filter(item => item !== value));
            }
        } else {
            setPlannersOptionSelected([...plannersOptionSelected, value]);
        }
    };

    /**
     * @function
     * @name handleAllPlanners
     * @description Function to update the plannersElegidos with all or nothing planners
     * @param {string} value - Checked in true o false
    */
    const handleAllPlanners = (event) => {
        if (event.target.checked) {
            setPlannersOptionSelected(planners.map(item =>item))
        } else {
            setPlannersOptionSelected([planners[0]])
        }
    };

    /**
     * @effect
     * @description This effect is executed when plannersElegidos is updated. 
     * Check in true the "Select All" check of the Planners if all planners are selected
    */
    useEffect(() => {
        const allSelected = plannersOptionSelected.length === planners.length;
        setAllPlannerSelected(allSelected)
    }, [plannersOptionSelected]);

    /**
     * @function
     * @name accumulatePlants
     * @description Function to update the plantsElegidas
     * @param {string} value - Value of plant selected
    */
    function accumulatePlants(value) {
        if (plantsOptionSelected.includes(value)) {
            if (plantsOptionSelected.length > 1) {
                setPlantsOptionSelected(plantsOptionSelected.filter(item => item !== value));
            }
        } else {
            setPlantsOptionSelected([...plantsOptionSelected, value]);
        }
    }

    /**
     * @function
     * @name handleAllPlants
     * @description Function to update the plantsElegidas with all or nothing plants
     * @param {string} value - Checked in true o false
    */
    const handleAllPlants = (event) => {
        if (event.target.checked) {
            setPlantsOptionSelected(plants)
        } else {
            setPlantsOptionSelected([plants[0]])
        }
    };

    /**
     * @effect
     * @description This effect is executed when plantsElegidas is updated. 
     * Check in true the "Select All" check of the Plants if all plants are selected
    */
    useEffect(() => {
        const allSelected = plantsOptionSelected.length === plants.length;
        setAllPlantSelected(allSelected)
    }, [plantsOptionSelected]);

    /**
     * @function
     * @name accumulateLevels
     * @description Function to update the levelsElegidos
     * @param {string} value - Value of level selected
    */
    function accumulateLevels(value) {
        if (levelsOptionSelected.includes(value)) {
            setLevelsOptionSelected(levelsOptionSelected.filter(item => item !== value));
        } else {
            setLevelsOptionSelected([...levelsOptionSelected, value]);
        }
    }

    /**
     * @function
     * @name handleAllLevels
     * @description Function to update the levelsElegidos with all or nothing levels
     * @param {string} value - Checked in true o false
    */
    const handleAllLevels = (event) => {
        if (event.target.checked) {
            setLevelsOptionSelected(levels)
        } else {
            setLevelsOptionSelected([levels[0]])
        }
    };

    /**
     * @effect
     * @description This effect is executed when levelsElegidos is updated. 
     * Check in true the "Select All" check of the Turnover if all levels are selected
    */
    useEffect(() => {
        const allSelected = levelsOptionSelected.length === levels.length;
        setAllLevelSelected(allSelected);
    }, [levelsOptionSelected]);

    /**
     * @function
     * @name accumulateMaterials
     * @description Function to update the materialsElegidos
     * @param {string} value - Value of level selected
    */
    function accumulateMaterials(value) {
        if (materialsOptionSelected.includes(value)) {
            if (materialsOptionSelected.length > 1) {
                setMaterialsOptionSelected(materialsOptionSelected.filter(item => item !== value));
            }
        } else {
            setMaterialsOptionSelected([...materialsOptionSelected, value]);
        }
    }

    /**
     * @function
     * @name handleAllMaterials
     * @description Function to update the materials with all or nothing material types
     * @param {string} value - Checked in true o false
    */
    const handleAllMaterials = (event) => {
        if (event.target.checked) {
            setMaterialsOptionSelected(materialTypes)
        } else {
            setMaterialsOptionSelected([materialTypes[0]])
        }
    };

    /**
     * @effect
     * @description This effect is executed when materialsElegidos is updated. 
     * Check in true the "Select All" check of the Material Types if all material types are selected
    */
    useEffect(() => {
        const allSelected = materialsOptionSelected.length === materialTypes.length;
        setAllMaterialsSelected(allSelected);
    }, [materialsOptionSelected]);

    /**
     * @function
     * @name handleCheckboxChange
     * @description Function to update the atypicalDemands with true or false
     * @param {string} value - Checked in true o false
    */
    const handleCheckboxChange = (event) => {
        setAtypicalDemands(event.target.checked);
    };

    /**
     * @effect
     * @description These effects are executed when selectedServiceLevel, selectedMonthsStock, 
     * selectedHistoryOption, selectedOption and atypicalDemands are updated. 
     * Send them to the main component
    */
    useEffect(() => {
        sendServiceLevel(parseFloat(selectedServiceLevel))
    }, [selectedServiceLevel]);

    useEffect(() => {
        sendMonthsMaximumStock(parseInt(selectedMonthsStock))
    }, [selectedMonthsStock]);

    useEffect(() => {
        sendMonthsHistory(selectedHistoryOption)
    }, [selectedHistoryOption]);

    useEffect(() => {
        sendOption(leadtimeOption)
    }, [leadtimeOption]);

    useEffect(() => {
        sendCheck(atypicalDemands)
    }, [atypicalDemands]);

    return (
        <div className='flex flex-row justify-between w-full h-full gap-2'>
            <div className='w-1/2 flex flex-col gap-y-3 px-3 h-full overflow-y-auto height-modal-config-policy'>
                {/* Space for the material input */}
                <div className='flex flex-col gap-y-1'>
                    <div className='flex flex-row justify-between w-full'>
                        <div className='flex flex-row justify-between items-center gap-1'>
                            <div className='small-square'></div>
                            <div>
                                <p className='mb-0 font-semibold text-neutral-200'>Material</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full'>
                        <input maxLength={maxLength} value={fingeredMaterial} onChange={handleMaterialChange} type="text" className="py-2 px-2 rounded-lg w-1/2 border-2 border-solid focus:outline-none focus:border-focused placeholder:font-semibold bg-transparent text-neutral-200" placeholder={t("policyManagement.materialPlaceholder")} />
                        <p className={`mb-0 text-neutral-200 text-xs 4xl:text-base ${warningMaterialWrote ? 'hidden':''}`}>
                            {t("policyManagement.materialAdvice")}
                        </p>
                    </div>
                </div>

                {/* Space for the planners options */}
                <div className='flex flex-col gap-y-1'>
                    <div className='flex flex-row justify-between w-full'>
                        <div className='flex flex-row justify-between items-center gap-1'>
                            <div className='small-square'></div>
                            <div>
                                <p className='mb-0 text-neutral-200 font-semibold'>{t("policyManagement.planners")}</p>
                            </div>
                        </div>
                        <div className='w-1/2 flex justify-end gap-2'>
                            <p className='mb-0 text-neutral-200 font-semibold'>{t("selectAll")}</p>
                            <div>
                                <input data-testid="select-all-planners" disabled={fingeredMaterial !== ""} type='checkbox' className='w-4 h-4 xl:w-5 xl:h-5' checked={allPlannerSelected} onChange={handleAllPlanners}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className='flex flex-wrap gap-x-2 gap-y-1'>
                            {plannersToShow.map((item) => (
                                <button data-testid={`planner-btn-${item}`} key={item} disabled={fingeredMaterial !== ""} onClick={() => accumulatePlanners(item)} className={`rounded w-0.75/5 4xl:w-0.50/5 font-semibold ${plannersOptionSelected.includes(item) ? 'bg-brand-300 text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100' : 'bg-neutral-500 text-neutral-200 hover:bg-brand-200 hover:text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100'}`}>
                                    <p className='mb-0 text-center'>{item}</p>
                                </button>
                            ))}
                            {plusButtonPlanners ? (
                                <IconButton data-testid="less-button-planners" onClick={() => setShowAllPlanners(false)} className='!p-0 !bg-brand-300' ><RemoveCircleOutlineIcon className='w-full h-full' /></IconButton>
                            ):null}

                            {lessButtonPlanners ? (
                                <IconButton data-testid="plus-button-planners" onClick={() => setShowAllPlanners(true)} className='!p-0 !bg-brand-300' ><AddCircleOutlineIcon className='w-full h-full' /></IconButton>
                            ):null}
                    </div>
                    <p className={`mb-0 text-neutral-200 text-xs 4xl:text-base ${warningPlanners ? 'hidden':''}`}>
                        {t("policyManagement.plannerAdvice")}
                    </p>
                </div>

                {/* Space for the plants options */}
                <div className='flex flex-col gap-y-1'>
                    <div className='flex flex-row justify-between w-full'>
                        <div className='flex flex-row justify-between items-center gap-1'>
                            <div className='small-square'></div>
                            <div>
                                <p className='mb-0 font-semibold text-neutral-200'>{t("policyManagement.plants")}</p>
                            </div>
                        </div>
                        <div className='w-1/2 flex justify-end gap-2'>
                            <p className='mb-0 text-neutral-200 font-semibold'>{t("selectAll")}</p>
                            <div>
                                <input data-testid="select-all-plants" disabled={fingeredMaterial !== ""} type='checkbox' className='w-4 h-4 xl:w-5 xl:h-5' checked={allPlantSelected} onChange={handleAllPlants} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap gap-x-2 gap-y-1'>
                        {plantsToShow.map((item) => (
                            <button data-testid={`plant-btn-${item}`} key={item} disabled={fingeredMaterial !== ""} onClick={() => accumulatePlants(item)} className={`rounded w-0.75/5 4xl:w-0.50/5 font-semibold ${plantsOptionSelected.includes(item) ? 'bg-brand-300 text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100' : 'bg-neutral-500 text-neutral-200 hover:bg-brand-200 hover:text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100'}`}>
                                <p className='mb-0 text-center'>{item}</p>
                            </button>
                        ))}
                        {plusButtonPlants ? (
                            <IconButton data-testid="less-button-plants" onClick={() => setShowAllPlants(false)} className='!p-0 !bg-brand-300' ><RemoveCircleOutlineIcon className='w-full h-full' /></IconButton>
                        ):null}

                        {lessButtonPlants ? (
                            <IconButton data-testid="plus-button-plants" onClick={() => setShowAllPlants(true)} className='!p-0 !bg-brand-300' ><AddCircleOutlineIcon className='w-full h-full' /></IconButton>
                        ):null}
                    </div>
                    <p className={`mb-0 text-neutral-200 text-xs 4xl:text-base ${warningPlants ? 'hidden':''}`}>
                        {t("policyManagement.plantAdvice")}
                    </p>
                </div>

                {/* Space for the turnover options */}
                <div className='flex flex-col gap-y-1'>
                    <div className='flex flex-row justify-between w-full'>
                        <div className='flex flex-row justify-between items-center gap-1'>
                            <div className='small-square'></div>
                            <div>
                                <p className='mb-0 font-semibold text-neutral-200'>{t("policyManagement.turnover")}</p>
                            </div>
                        </div>
                        <div className='w-1/2 flex justify-end gap-2'>
                            <p className='mb-0 font-semibold text-neutral-200'>{t("selectAll")}</p>
                            <div>
                                <input data-testid="select-all-levels" disabled={fingeredMaterial !== ""} type='checkbox' className='w-4 h-4 xl:w-5 xl:h-5' checked={allLevelSelected} onChange={handleAllLevels} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap gap-x-2 gap-y-1'>
                        {levels.map((item) => (
                            <button data-testid={`level-btn-${item}`} key={item} disabled={fingeredMaterial !== ""} onClick={() => accumulateLevels(item)} className={`rounded w-0.50/5 font-semibold ${levelsOptionSelected.includes(item) ? 'bg-brand-300 text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100' : 'bg-neutral-500 text-neutral-200 hover:bg-brand-200 hover:text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100'}`}>
                                <p className='mb-0 text-center'>{item}</p>
                            </button>
                        ))}
                    </div>
                    <p className={`mb-0 text-neutral-200 text-xs 4xl:text-base ${warningPlants ? 'hidden':''}`}>
                        {t("policyManagement.plantAdvice")}
                    </p>
                </div>

                {/* Space for the material type options */}
                <div className='flex flex-col gap-y-1'>
                    <div className='flex flex-row justify-between w-full'>
                        <div className='flex flex-row justify-between items-center gap-1'>
                            <div className='small-square'></div>
                            <div>
                                <p className='mb-0 font-semibold text-neutral-200 4xl:text-lg'>{t("policyManagement.materialType")}</p>
                            </div>
                        </div>
                        <div className='w-1/2 flex justify-end gap-2'>
                            <p className='mb-0 font-semibold text-neutral-200'>{t("selectAll")}</p>
                            <div>
                                <input data-testid="select-all-materials" disabled={fingeredMaterial !== ""} type='checkbox' className='w-4 h-4 xl:w-5 xl:h-5 rounded' checked={allMaterialsSelected} onChange={handleAllMaterials} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap gap-x-2 gap-y-1'>
                        {materialsToShow.map((item) => (
                            <button data-testid={`material-btn-${item}`} key={item} disabled={fingeredMaterial !== ""} onClick={() => accumulateMaterials(item)} className={`rounded w-0.75/5 4xl:w-0.50/5 font-semibold ${materialsOptionSelected.includes(item) ? 'bg-brand-300 text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100' : 'bg-neutral-500 text-neutral-200 hover:bg-brand-200 hover:text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100'}`}>
                                <p className='mb-0 text-center'>{item}</p>
                            </button>
                        ))}
                        {lessButtonMaterials ? (
                            <IconButton data-testid="less-button-materials" onClick={() => setShowAllMaterials(true)} className='!p-0 !bg-brand-300' ><AddCircleOutlineIcon className='w-full h-full' /></IconButton>
                        ): null}
                        {plusButtonMaterials ? (
                            <IconButton data-testid="plus-button-materials" onClick={() => setShowAllMaterials(false)} className='!p-0 !bg-brand-300' ><RemoveCircleOutlineIcon className='w-full h-full' /></IconButton>
                        ): null}
                    </div>
                    <p className={`mb-0 text-neutral-200 text-xs 4xl:text-base ${warningMaterialType ? 'hidden':''}`}>
                        {t("policyManagement.materialTypeAdvice")}
                    </p>
                </div>
            </div>
            <div className='w-1/2'>
                <div className='flex flex-col mx-4 w-full gap-y-3'>
                    {/* Space for the service level dropdown */}
                    <div className='flex flex-row gap-4 items-center'>
                        <div>
                            <p className='mb-0 text-neutral-200 font-semibold'>{t("policyManagement.serviceLevel")}</p>
                        </div>
                        <div className='flex justify-start w-2/5 xl:w-1/3'>
                            <Select value={selectedServiceLevel} className='!bg-neutral-400 !text-neutral-200 w-1/2 h-10'>
                                {serviceLevel.map((item) =>(
                                    <MenuItem key={item} value={item} classes={{selected: "!bg-neutral-500"}} className='!bg-neutral-400 hover:!bg-neutral-500 !text-neutral-200' onClick={() => handleOptionServiceLevel(item)}>{item}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>

                    {/* Space for the Months for maximum stock dropdown */}
                    <div className='flex flex-row gap-4 w-full items-center'>
                        <div>
                            <p className='mb-0 text-neutral-200 font-semibold'>{t("policyManagement.monthsMaximum")}</p>
                        </div>
                        <div className='flex justify-start w-1/3 xl:w-1/4'>
                            <Select value={selectedMonthsStock} className='!bg-neutral-400 !text-neutral-200 w-1/2 h-10'>
                                {maximumStock.map((item) =>(
                                    <MenuItem key={item} value={item} classes={{selected: "!bg-neutral-500"}} className='!bg-neutral-400 hover:!bg-neutral-500 !text-neutral-200' onClick={() => handleOptionMonthsStock(item)}>{item}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>

                    {/* Space for the Months of history dropdown */}
                    <div className='flex flex-row gap-4 w-full items-center'>
                        <div>
                            <p className='mb-0 text-neutral-200 font-semibold'>{t("policyManagement.monthsHistory")}</p>
                        </div>
                        <div>
                            <Select value={selectedHistoryOption} className='!bg-neutral-400 !text-neutral-200 h-10'>
                                <MenuItem value={'12M'} classes={{selected: "!bg-neutral-500"}} className='!bg-neutral-400 hover:!bg-neutral-500 !text-neutral-200' onClick={() => handleOptionHistory('12M')}>12M</MenuItem>
                            </Select>
                        </div>
                    </div>

                    {/* Space for the Leadtime calculation dropdown */}
                    <div className='flex flex-row gap-4 w-full items-center'>
                        <div>
                            <p className='mb-0 text-neutral-200 font-semibold'>{t("policyManagement.leadtime")}</p>
                        </div>
                        <div>
                            <Select value={leadtimeOption} className='!bg-brand-300 !text-neutral-400 h-10'>
                                <MenuItem value={'Average'} classes={{selected: "!bg-brand-400"}} className='!bg-brand-300 hover:!bg-brand-400 !text-neutral-400' onClick={() => handleLeadtimeOption('Average')}>{t("policyManagement.leadtimeAverage")}</MenuItem>
                                <MenuItem value={'Business Rule'} classes={{selected: "!bg-brand-400"}} className='!bg-brand-300 hover:!bg-brand-400 !text-neutral-400' onClick={() => handleLeadtimeOption('Business Rule')}>{t("policyManagement.leadtimeBusiness")}</MenuItem>
                            </Select>
                        </div>
                    </div>

                    {/* Space for the Exclude atypical demands check */}
                    <div className='flex flex-row gap-4 w-full items-center'>
                        <div>
                            <p className='mb-0 text-neutral-200 font-semibold'>{t("policyManagement.excludeAtypical")}</p>
                        </div>
                        <div className='flex items-center'>
                            <input type='checkbox' className='w-5 h-5 xl:w-5 xl:h-5' checked={atypicalDemands} onChange={handleCheckboxChange}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ModalPolicy.propTypes = {
    planners: PropTypes.array, 
    plants: PropTypes.array, 
    wantedMaterial: PropTypes.string, 
    plannersSelected: PropTypes.array, 
    plantsSelected: PropTypes.array, 
    levelsSelected: PropTypes.array,
    materialTypes: PropTypes.array, 
    materialsSelected: PropTypes.array, 
    serviceLevelDefault: PropTypes.number, 
    monthsMaximunStock: PropTypes.number, 
    monthsHistory: PropTypes.string, 
    checkAtypical: PropTypes.bool, 
    leadtime: PropTypes.string, 
    sendMaterial: PropTypes.func, 
    sendNewPlanners: PropTypes.func, 
    sendNewPlants: PropTypes.func, 
    sendNewLevels: PropTypes.func, 
    sendMaterialsType: PropTypes.func, 
    sendServiceLevel: PropTypes.func, 
    sendMonthsMaximumStock: PropTypes.func, 
    sendMonthsHistory: PropTypes.func, 
    sendOption: PropTypes.func, 
    sendCheck: PropTypes.func
}

export default ModalPolicy;
