import { createContext, useState, useMemo } from "react";
import PropTypes from 'prop-types';

export const CurrencyContext = createContext()

/**
 * @context
 * @description This context contains the currency
*/
const CurrencyContextProvider = ({children}) => {

  const [currency, setCurrency] = useState("USD");
  const [locale, setLocale] = useState("en-US");
  const [changeRates, setChangeRates] = useState({
    "USD": 1,
    "COP": 4402.8638,
    "EUR": 0.9497,
    "MXN": 20.1744,
    "PEN": 3.7295,
    "HNL": 25.3539,
    "PAB": 1,
    "GBP": 0.7833,
    "CHF": 0.8817,
  });
  const [changeRateSelected, setChangeRateSelected] = useState(1);

  const data = useMemo(() => ({
    currency,
    setCurrency,
    locale, 
    setLocale,
    changeRates,
    setChangeRates,
    changeRateSelected,
    setChangeRateSelected
  }), [currency, setCurrency, locale, setLocale, changeRates, setChangeRates, changeRateSelected, setChangeRateSelected]);

  return (
    <CurrencyContext.Provider value={data}>
        {children}
    </CurrencyContext.Provider>
  )
}

CurrencyContextProvider.propTypes = {
  children: PropTypes.node
}

export default CurrencyContextProvider
