import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

function postPlantsPerMaterial(args, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/get-plants-by-material-code/?zone=${zone}&${apiParam}`, args, config);
}

function postMarcInformationDemand(args, zone) {
  const config = {
    headers: {
      'Authorization': Cookies.get('token')
    }
  }
  
  return axios.post(`${domain}/ipa_app/marc-information/demand/?zone=${zone}&${apiParam}`, args, config, {
      timeout: 300000
  });
}

function postMarcInformationStockHistory(args, zone) {
  const config = {
    headers: {
      'Authorization': Cookies.get('token')
    }
  }
  return axios.post(`${domain}/ipa_app/marc-information/stock-history/?zone=${zone}&${apiParam}`, args, config, {
      timeout: 300000
  });
}

function postMarcInformationEssentialInformation(args, zone) {
  const config = {
    headers: {
      'Authorization': Cookies.get('token')
    }
  }
  return axios.post(`${domain}/ipa_app/marc-information/essential-information/?zone=${zone}&${apiParam}`, args, config, {
      timeout: 300000
  });
}

export { postPlantsPerMaterial, postMarcInformationDemand, postMarcInformationStockHistory, postMarcInformationEssentialInformation };
