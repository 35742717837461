import React, {useContext} from 'react'
import { formatCurrency } from '../../../models/generics';
import { CurrencyContext } from '../../../context/CurrencyContext';
import PropTypes from 'prop-types';

const LabelNegotiation = (props) => {
    
    const { x, y, value, height, width  } = props;

    const {currency, locale, changeRateSelected} = useContext(CurrencyContext)

    return (
        <text x={x + width / 2} y={y + height / 1.8} className='text-black font-semibold text-xs 4xl:text-lg' textAnchor="start" dominantBaseline="middle">
            {formatCurrency(value, "standard", currency, locale, changeRateSelected)}
        </text>
    )
}

LabelNegotiation.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    value: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
}

export default LabelNegotiation
