import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addMaterial, addPlants, addStockHistory, addEssentialInfo, addDemands, addPlantSelected } from '../../../redux/materialSlice';
import '../../../styles/material_style.css'
import Loading from '../../pure/loading';
import empty from '../../../images/materialEmpty.png'
import leadTime from '../../../images/leadTime.png'
import averageConsumption from '../../../images/averageConsumption.png'
import simulatedConsumption from '../../../images/simulatedConsumption.png'
import inventoryLevel from '../../../images/inventoryLevel.png'
import planner from '../../../images/planner.png'
import reorderPoint from '../../../images/reorderPoint.png'
import planningFeature from '../../../images/planingFeature.png'
import safetyStock from '../../../images/safetyStock.png'
import critical from '../../../images/critical.png'
import maxStock from '../../../images/maxStock.png'
import inventoryDays from '../../../images/inventoryDays.png'
import SearchIcon from '@mui/icons-material/Search';
import NearMeIcon from '@mui/icons-material/NearMe';
import Swal from 'sweetalert2';
import { IconButton, Select, MenuItem } from '@mui/material';
import LegendInventory from '../../pure/legendsCharts/legendInventory';
import LegendMaterialDemand from '../../pure/legendsCharts/legendMaterialDemand';
import TooltipMaterialDemands from '../../pure/tooltipCharts/tooltipMaterialDemands';
import TooltipMaterialInventory from '../../pure/tooltipCharts/tooltipMaterialInventory';
import LabelMaterial from '../../pure/labelsCharts/labelMaterial';
import DotMaterialDemands from '../../pure/dotCharts/dotMaterialDemands';
import DotMaterialInventory from '../../pure/dotCharts/dotMaterialInventory';
import ActiveDotMaterialDemands from '../../pure/dotCharts/activeDotMaterialDemands';
import ActiveDotMaterialInventory from '../../pure/dotCharts/activeDotMaterialInventory';
import { ZoneContext } from '../../../context/ZoneContext';
import { postPlantsPerMaterial, postMarcInformationDemand, postMarcInformationStockHistory, postMarcInformationEssentialInformation } from '../../../models/material_data';
import { addThousandPoints, removeAllCookies } from '../../../models/generics';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

const Material = () => {
    /**
     * @let
     * @description Temporal variable to save the plants when make the POST method
    */
    let tempPlants = []

    /**
     * @const
     * @description Constant to have all year months
    */
    const months = ["None", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    
    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    /**
     * @hook
     * @name dispatch
     * @type {function}
     * @description It provides the ability save and get data in the redux store
    */
    const dispatch = useDispatch()

    /**
     * @hook
     * @name material
     * @type {function}
     * @description It provides the global data in the redux store
    */
    const material = useSelector((state) => state.material)

    /**
     * @context
     * @description Access to get the global state of the ZoneContext
    */
    const {zone} = useContext(ZoneContext)

    /**
     * @state
     * @type {string}
     * @default "inventory"
     * @description Local state to know what chart is shown
    */
    const [chart, setChart] = useState("inventory");

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local state to save the material wrote to make the post method
    */
    const [materialWrote, setMaterialWrote] = useState("");

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to show the logoCarga
    */
    const [loading, setLoading] = useState(false);

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local State to save the text to show while the view load according with the endpoint executed
    */
    const [loadText, setLoadText] = useState("");
    const [materialPlants, setMaterialPlants] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the ticks for the inventory chart
    */
    const [ticksInventory, setTicksInventory] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the ticks for the demands chart
    */
    const [ticksDemands, setTicksDemands] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the stock history after the POST method and show in the inventory chart
    */
    const [stockHistory, setStockHistory] = useState([]);
    const [essentialInfo, setEssentialInfo] = useState({});
    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the demands of the material after the POST method
    */
    const [demands, setDemands] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the essential information to show in the tables on top of the charts
    */
    const [essentialRow, setEssentialRow] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the essential information to show in the tables on the left side of the charts
    */
    const [essentialCol, setEssentialCol] = useState([]);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local State to calculate the target stock for the inventory chart
    */
    const [targetStock, setTargetStock] = useState(0);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local State to know if show or not the view
    */
    const [showView, setShowView] = useState(false);

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local State to know if the critical or not to show an specific value (Yes or No)
    */
    const [criticalValue, setCriticalValue] = useState("");

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local State to save the background of the button to change to inventory chart
    */
    const [backgroundButtonInventory, setBackgroundButtonInventory] = useState("");

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local State to save the background of the button to change to demand chart
    */
    const [backgroundButtonDemand, setBackgroundButtonDemand] = useState("");

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the options to filter the demands chart with dates
    */
    const [filterOptionsDemands, setFilterOptionsDemands] = useState([]);

    /**
     * @state
     * @type {object}
     * @default {}
     * @description Local State to save the filter demands chart option selected
    */
    const [filterSelectedDemands, setFilterSelectedDemands] = useState({});

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the options to filter the inventory chart with dates
    */
    const [filterOptionsInventory, setFilterOptionsInventory] = useState([]);

    /**
     * @state
     * @type {object}
     * @default {}
     * @description Local State to save the filter inventory chart option selected
    */
    const [filterSelectedInventory, setFilterSelectedInventory] = useState({});

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local State to show or not the select to filter the inventory chart
    */
    const [selectInventoryClass, setSelectInventoryClass] = useState("");

     /**
     * @state
     * @type {string}
     * @default ""
     * @description Local State to show or not the select to filter the demands chart
    */
    const [selectDemandClass, setSelectDemandClass] = useState("");

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local State to save the purchase rounding of the material
    */
    const [purchaseRounding, setPurchaseRounding] = useState(0);

    /**
     * @hook
     * @name navigate
     * @type {function}
     * @description It provides the ability to the app with the country selected
    */
    const navigate = useNavigate()

    useEffect(() => {
        if (chart === "inventory" && stockHistory.length > 0) {
            setSelectInventoryClass('!bg-brand-300 !text-neutral-400 !h-full w-full');
        } else if (chart === "demand") {
            setSelectInventoryClass('!hidden');
        } else {
            setSelectInventoryClass('!hidden');
        }

        if (chart === "demand" && demands.length > 0) {
            setSelectDemandClass('!bg-brand-300 !text-neutral-400 !h-full w-full');
        } else if (chart === "inventory") {
            setSelectDemandClass('!hidden');
        } else {
            setSelectDemandClass('!hidden');
        }
    }, [chart, stockHistory, demands]);

    function getDaysMonthsDemands(month, year) {
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
            let formattedDate = date.toISOString();
            days.push({
                "date": formattedDate,
                "demand": 0,
                "unit_measure": "M"
            });
            date.setDate(date.getDate() + 1);
        }
        return days;
    }

    function getDaysMothsInventory(month, year) {
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
            let formattedDate = date.toISOString();
            days.push({
                "date": formattedDate,
                "stock": 0,
                "unit_measure": "M"
            });
            date.setDate(date.getDate() + 1);
        }
        return days;
    }

    function getMonthsYear(array) {
        let monthsAndYear = {};
        let id = 1;
        if (array.length > 0) {
            array.map((obj) => {
                let date = new Date(obj.date);
                let month = date.getMonth();
                let year = date.getFullYear();
                monthsAndYear[`${month}-${year}`] = {id: id++, month: month, year: year, value:`${months[month]}-${year}`, valueMonth:months[month]};
            });
        }
        let result = Object.values(monthsAndYear);
        result.unshift({id: 0, month: "Resume", año: 0, value:'Resume', valueMonth:'Resume'});
        return result;
    }

    const handleOptionFilterInventory = (option) => {
        setFilterSelectedInventory(option.value);
        let stockHistory = material.stockHistory.map(obj => {
            let newObj = {...obj};
            newObj.date = new Date(new Date(newObj.date).setUTCHours(5)).toISOString().slice(0, -5) + 'Z';
            return newObj;
        });
        if (option.value !== "Resume") {
            let fullMonth = getDaysMothsInventory(option.month, option.year)
            fullMonth = fullMonth.map(obj1 => {
                obj1.date = new Date(new Date(obj1.date).setUTCHours(5)).toISOString().slice(0, -5) + 'Z';
                let obj2 = stockHistory.find(obj2 => obj2.date === obj1.date);
                return obj2 || obj1;
            });
            setStockHistory(fullMonth)
        } else {
            setStockHistory(stockHistory)
        }
    };

    const handleOptionFilterDemands = (option) => {
        setFilterSelectedDemands(option.value);
        if (option.value !== "Resume") {
            let fullMonth = getDaysMonthsDemands(option.month, option.year)
            fullMonth = fullMonth.map(obj1 => {
                obj1.date = new Date(new Date(obj1.date).setUTCHours(5)).toISOString().slice(0, -5) + 'Z';
                let obj2 = material.demands.find(obj2 => obj2.date === obj1.date);
                return obj2 || obj1;
            });
            setDemands(fullMonth)
        } else {
            setDemands(material.demands)
        }
    };

    useEffect(() => {
        setMaterialPlants(material.plants)
        setMaterialWrote(material.materialWrote)
        if (material.stockHistory !== null) {
            let stockHistory = material.stockHistory.length > 0 ? material.stockHistory.map(obj => {
                let newObj = {...obj};
                newObj.date = new Date(new Date(newObj.date).setUTCHours(5)).toISOString().slice(0, -5) + 'Z';
                return newObj;
            }) : [];
            setStockHistory(stockHistory.map(d => ({ ...d, date: new Date(d.date) })))
            const dates = getMonthsYear(material.stockHistory)
            setFilterOptionsInventory(dates)
            setFilterSelectedInventory(dates[0].value)
        } else {
            setStockHistory([])
        }
        setEssentialInfo(material.essentialInfo)
        if (material.demands !== null) {
            setDemands(material.demands.length > 0 ? material.demands.map(d => ({ ...d, date: new Date(d.date) })):[])
            const dates = getMonthsYear(material.demands)
            setFilterOptionsDemands(dates)
            setFilterSelectedDemands(dates[0].value)
        } else {
            setDemands([])
        }
    }, [material]);

    useEffect(() => {
        let testArray = stockHistory.map(item => item.stock)
        const targetStock = (material.essentialInfo.max_stock + material.essentialInfo.reorder_point)/2
        let stockArray = [material.essentialInfo.max_stock, material.essentialInfo.reorder_point, material.essentialInfo.safety_stock, targetStock]
        let uniqueStocks = [...new Set([...testArray, ...stockArray].map(item => item))];
        uniqueStocks.sort((a, b) => a - b);
        setTicksInventory(uniqueStocks)
    }, [stockHistory, material.essentialInfo]);

    useEffect(() => {
        let uniqueDemands = [...new Set(demands.map(item => item.demand))]
        uniqueDemands.sort((a, b) => a - b);
        setTicksDemands(uniqueDemands)
    }, [demands]);

    useEffect(() => {
        if (chart === "inventory") {
            setBackgroundButtonInventory("bg-white")
            setBackgroundButtonDemand("bg-neutral-300")
        } else {
            setBackgroundButtonInventory("bg-neutral-300")
            setBackgroundButtonDemand("bg-white")
        }
    }, [chart]);

    useEffect(() => {
        if (materialPlants.length > 0 && (stockHistory.length > 0 || demands.length > 0)) {
            setShowView(true)
        } else {
            setShowView(false)
        }
    }, [materialPlants, stockHistory, demands]);

    useEffect(() => {
        
    }, [material.plants, stockHistory, demands]);

    useEffect(() => {
        let average_demand = 0
        let simulated_demand = 0
        let inventory_days = 0
        if (material.essentialInfo.average_demand !== null && !isNaN(material.essentialInfo.average_demand)) {
            average_demand = addThousandPoints(material.essentialInfo.average_demand)
        }

        if (material.essentialInfo.simulated_demand !== null && !isNaN(material.essentialInfo.simulated_demand)) {
            simulated_demand = addThousandPoints(material.essentialInfo.simulated_demand)
        }

        if (material.essentialInfo.inventory_days !== null && !isNaN(material.essentialInfo.inventory_days)) {
            inventory_days = addThousandPoints(material.essentialInfo.inventory_days)
        }

        if (material.essentialInfo.min_order_size !== null && !isNaN(material.essentialInfo.min_order_size)) {
            const value = addThousandPoints(material.essentialInfo.min_order_size)
            setPurchaseRounding(value) 
        }

        const essentialRow = [
            {"id":1, "icon":leadTime, "description":"Lead Time", "value":essentialInfo.lead_time },
            {"id":2, "icon":averageConsumption, "description":t("material.consumoPromedio"), "value":average_demand },
            {"id":3, "icon":simulatedConsumption, "description":t("material.consumoSimulado"), "value": simulated_demand },
            {"id":4, "icon":inventoryLevel, "description":t("material.nivelInventario"), "value": addThousandPoints(material.essentialInfo.inventory_level) },
            {"id":5, "icon":planner, "description":t("material.planner"), "value":material.essentialInfo.planner },
            {"id":6, "icon":reorderPoint, "description":t("material.reorderPoint"), "value": addThousandPoints(material.essentialInfo.reorder_point) },
            {"id":7, "icon":planningFeature, "description":t("material.planningFeature"), "value": addThousandPoints(material.essentialInfo.planning_feature) },
        ]

        const essentialCol = [
            {"id":1, "icon":maxStock, "description":t("material.graficas.stockMax"), "value":addThousandPoints(material.essentialInfo.max_stock) },
            {"id":2, "icon":safetyStock, "description":t("material.safetyStock"), "value": addThousandPoints(material.essentialInfo.safety_stock) },
            {"id":3, "icon":inventoryDays, "description":t("material.inventaryDays"), "value":inventory_days },
        ]
        setEssentialRow(essentialRow)
        setEssentialCol(essentialCol)

        const targetStock = (material.essentialInfo.max_stock + material.essentialInfo.reorder_point)/2
        setTargetStock(targetStock)
        if (material.essentialInfo.is_critical === "X") {
            setCriticalValue("Yes")
        } else {
            setCriticalValue("No")
        }
    }, [material.essentialInfo, t]);

    const handleMaterialChange = (event) => {
        const inputValue = event.target.value.replace(/\D/g, '').slice(0, 7);
        setMaterialWrote(inputValue);
    }

    function searchPlant() {
        setLoading(true)
        setLoadText(t("material.cargaText.plants"))
        const data = {
            "material_code": materialWrote
        }
        postPlantsPerMaterial(data, zone).then((response) => {
            if (response.data.length > 0) {
                dispatch(addMaterial(materialWrote))
                tempPlants = response.data
                searchPlantMaterial(response.data[0])
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("material.emptyApi")
                })
                setLoading(false)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    removeAllCookies()
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        });
    }

    async function searchPlantMaterial(item) {
        setLoading(true)
        const data = {
            "material_code": materialWrote,
            "plant_id": item.id
        }

        try {
            setLoadText(t("material.cargaText.demands"))
            const tempDemands = await postMarcInformationDemand(data, zone);
            setLoadText(t("material.cargaText.stockHistoy"))
            const tempStockHistory = await postMarcInformationStockHistory(data, zone);
            setLoadText(t("material.cargaText.essentialInfo"))
            const tempEssentialInformation = await postMarcInformationEssentialInformation(data, zone);
            dispatch(addPlantSelected(item))
            if (tempPlants.length > 0) {
                dispatch(addPlants(tempPlants))
            }
            if (tempDemands.data === null || tempDemands.data === "") {
                dispatch(addDemands([]))
            } else {
                dispatch(addDemands(tempDemands.data))
            }
            if (tempStockHistory.data === null || tempStockHistory.data === "") {
                dispatch(addStockHistory([]))
            } else {
                const cleanedStockHistory = Object.values(tempStockHistory.data.reduce((acc, obj) => {
                    acc[obj.date] = obj;
                    return acc;
                }, {}));
                dispatch(addStockHistory(cleanedStockHistory))
            }
            dispatch(addEssentialInfo(tempEssentialInformation.data))
            tempPlants = []
            setLoading(false)
        } catch (error) {
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    removeAllCookies()
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        }
    }

    const detectKeyDown = (e) => {
        if (e.key === "Enter" && materialWrote.length >= 6) {
            searchPlant()
            e.target.blur();
        }
    }

    const legends = [
        {id: 0, color: "#3c8dc0", text: t("material.graficas.inventoryLevel")},
        {id: 1, color: "black", text: t("material.graficas.stockMax")},
        {id: 2, color: "#FFC629", text: t("material.graficas.targetStock")},
        {id: 3, color: "#00B261", text: t("material.graficas.reorderPoint")},
        {id: 4, color: "#C92323", text: t("material.graficas.safetyStock")}
    ];

    const legendDemand = {
        color: "#3c8dc0", text: t("material.graficas.consumo")
    }

    function validateColor(item) {
        let color = ""
        if (item.color_light === "in-range") {
            color = "bg-positive-300"
        } else if (item.color_light === "overstock") {
            color = "bg-danger-300"
        } else if (item.color_light === "understock") {
            color = "bg-orange-400"
        } else if (item.color_light === "transit") {
            color = "bg-brand-300"
        } else {
            color = "bg-transparent"
        }
        return color
    }

    return (
        <div className='wallpaper flex flex-col w-4.5/5 h-full gap-y-1'>
            {/* Space to show logoCarga if the local state "loading" is true */}
            {loading ? (
                <Loading text={loadText} />
            ): null}
            <div className='bg-neutral-300 h-0.50/5 4xl:h-0.25/5 w-full flex flex-row px-2 items-center gap-6'>
                <div className="flex flex-row w-1/4 xl:w-1/5 h-3/5">
                    <IconButton disabled={materialWrote.length < 6} onClick={searchPlant} className='!bg-neutral-200 !rounded-l-lg !rounded-r-none'>
                        <SearchIcon />
                    </IconButton>
                    <input maxLength={7} value={materialWrote} onChange={handleMaterialChange} onKeyDown={detectKeyDown} type="text" className="py-2 px-2 rounded-lg w-full rounded-l-none border-2 border-solid focus:outline-none focus:border-focused placeholder:font-semibold" placeholder={t("material.placeholder")} />
                </div>
                <div className={`${showView ? 'flex flex-row justify-between w-4/5':'hidden'}`}>
                    <div className='flex flex-row gap-2 items-center'>
                        <p className='mb-0 font-semibold text-sm xl:text-base 2xl:text-lg 3xl:text-xl'>{t("material.type")}:</p>
                        <p className='mb-0  text-sm xl:text-base 2xl:text-lg 3xl:text-xl'>{material.essentialInfo.material_type}</p>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <p className='mb-0 font-semibold  text-sm xl:text-base 2xl:text-lg 3xl:text-xl'>{t("material.description")}:</p>
                        <p className='mb-0  text-sm xl:text-base 2xl:text-lg 3xl:text-xl'>{material.essentialInfo.material_desc}</p>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <p className='mb-0 font-semibold  text-sm xl:text-base 2xl:text-lg 3xl:text-xl'>{t("material.purchaseRounding")}:</p>
                        <p className='mb-0  text-sm xl:text-base 2xl:text-lg 3xl:text-xl'>{purchaseRounding}</p>
                    </div>
                </div>
            </div>
            {showView ? (
                <div className='flex flex-col w-full h-4.5/5'>
                    <div className='grid grid-cols-4 w-full h-1/4 4xl:h-1.5/5 overflow-y-scroll boxes-row gap-y-1 2xl:gap-y-2 gap-x-1 px-1 py-1 content-start'>
                        {material.plants.map((item) => (
                            <button disabled={item.name === material.plantSelected.name} onClick={() => searchPlantMaterial(item)} key={item.id} className={`${item.name === material.plantSelected.name ? "bg-neutral-300":"bg-neutral-200"} flex content-center items-center hover:bg-neutral-300 h-full rounded-lg gap-2 px-1 py-2`}>
                                <div className={`items-center flex justify-center w-5 h-5 xl:w-6 xl:h-6 3xl:w-8 3xl:h-8 rounded-full ${validateColor(item)}`}>
                                    <NearMeIcon className='!w-3/5' />
                                </div>
                                <p className='mb-0 text-xs 2xl:text-sm 3xl:text-base 4xl:text-lg'>{item.name}</p>
                            </button>
                        ))}
                    </div>
                    <div className='flex flex-col h-3/4'>
                        <div className='flex justify-center items-center w-full h-0.25/5 bg-neutral-background'>
                            <p className='mb-0 font-semibold text-xs 2xl:text-sm 3xl:text-base 4xl:text-lg'>{material.plantSelected.name} : {material.materialWrote}</p>
                        </div>
                        <div className='px-2 flex flex-row items-center gap-1 w-full bg-neutral-200 h-0.75/5 4xl:h-0.50/5 py-1'>
                            {essentialRow.map((item) => (
                                <div key={item.id} className='flex items-center bg-neutral-300 w-0.75/5 h-full rounded-xl py-1 px-1 xl:px-2 gap-2'>
                                    <div className='flex justify-center w-6 h-6 2xl:w-8 2xl:h-8 3xl:w-9 3xl:h-9 4xl:w-10 4xl:h-10 rounded-full'>
                                        <img src={item.icon} alt='Carga Logo' className='icon-info' />
                                    </div>
                                    <div className='flex flex-col'>
                                        <p className='mb-0 font-semibold text-xs 2xl:text-sm 4xl:text-xl'>{item.description}</p>
                                        <p className='mb-0 text-xs 2xl:text-sm 4xl:text-xl'>{item.value}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='px-2 flex flex-row gap-1 w-full bg-neutral-background h-4/5 4xl:h-4.25/5 py-2'>
                            <div className='flex flex-col w-0.75/5 justify-between'>
                                <div className='bg-white rounded-lg h-1/5 flex px-3 gap-2 items-center w-full'>
                                    <img src={critical} alt='critical icon' className='w-6 h-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10' />
                                    <div className='flex flex-col'>
                                        <p className='mb-0 font-semibold text-xs 2xl:text-base 4xl:text-xl'>Critical</p>
                                        <p className='mb-0 text-xs 2xl:text-base 4xl:text-xl'>{criticalValue}</p>
                                    </div>
                                </div>
                                {essentialCol.map((item) => (
                                    <div key={item.id} className='bg-white rounded-lg h-1/5 flex px-3 gap-2 items-center w-full'>
                                        <img src={item.icon} alt='essential column icon' className='w-6 h-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10' />
                                        <div className='flex flex-col'>
                                            <p className='mb-0 font-semibold text-xs 2xl:text-base 4xl:text-xl'>{item.description}</p>
                                            <p className='mb-0 text-xs 2xl:text-base 4xl:text-xl'>{item.value === null ? 0 : item.value}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='flex flex-col w-4.25/5 h-full'>
                                <div className='flex flex-row justify-between w-full h-0.50/5 4xl:h-0.25/5'>
                                    <div className='flex w-1/2 gap-3'>
                                        <button disabled={chart === "inventory"} onClick={() => setChart("inventory")} className={`${backgroundButtonInventory} w-1/2 h-full text-xs 2xl:text-base 4xl:text-lg border-0 flex justify-center items-center rounded-t-lg`}>
                                            <p className='mb-0 font-semibold'>{t("material.graficas.inventario")}</p>
                                        </button>
                                        <button disabled={chart === "demand"} onClick={() => setChart("demand")} className={`${backgroundButtonDemand} w-1/2 h-full text-xs 2xl:text-base 4xl:text-lg  border-0 flex justify-center items-center rounded-t-lg`}>
                                            <p className='mb-0 font-semibold'>{t("material.graficas.consumo")}</p>
                                        </button>
                                    </div>
                                    <div className='w-1/4 xl:w-1/5 3xl:w-0.75/5'>
                                        {filterOptionsInventory.length > 0 ? (
                                            <Select value={filterSelectedInventory} className={`${selectInventoryClass}`}>
                                                {filterOptionsInventory.map((item) =>(
                                                    <MenuItem key={item.id} value={item.value} classes={{selected: "!bg-brand-400"}} className='!bg-brand-300 hover:!bg-brand-400 !text-neutral-400' onClick={() => handleOptionFilterInventory(item)}>{item.value !== "Resume" ? `${t("material."+item.valueMonth)} - ${item.year}` : t("material."+item.value)}</MenuItem>
                                                ))}
                                            </Select>
                                        ) : null}

                                        {filterOptionsDemands.length > 0 ? (
                                            <Select value={filterSelectedDemands} className={`${selectDemandClass}`}>
                                                {filterOptionsDemands.map((item) =>(
                                                    <MenuItem key={item.id} value={item.value} classes={{selected: "!bg-brand-400"}} className='!bg-brand-300 hover:!bg-brand-400 !text-neutral-400' onClick={() => handleOptionFilterDemands(item)}>{item.value !== "Resume" ? `${t("material."+item.valueMonth)} - ${item.year}` : t("material."+item.value)}</MenuItem>
                                                ))}
                                            </Select>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='w-full h-4.5/5 4xl:h-4.75/5 bg-white rounded-b-lg'>
                                    {chart === "inventory" ? (
                                        <>
                                            {stockHistory.length > 0 ? (
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <LineChart width={500} height={300} data={stockHistory.map(item => ({...item, date: new Date(item.date).getTime()}))}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" padding={{ right: 10, left: 10 }}
                                                                tickFormatter={(date) => new Intl.DateTimeFormat('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)} 
                                                                type="number" domain={['dataMin', 'dataMax']} scale="time" className='text-xs 4xl:text-sm'/>
                                                        <YAxis tickFormatter={(stock) => addThousandPoints(stock)} ticks={ticksInventory} domain={[Math.min(...ticksInventory), Math.max(...ticksInventory)]} padding={{ top: 10, bottom: 10 }} className='text-xs 4xl:text-sm' />
                                                        <Tooltip content={<TooltipMaterialInventory />} />
                                                        <Legend verticalAlign="top" content={<LegendInventory legends={legends} />} />
                                                        <ReferenceLine className='text-xs 2xl:text-sm 3xl:text-base' y={essentialInfo.max_stock} stroke="black" label={addThousandPoints(essentialInfo.max_stock).toString()} strokeWidth={3} />
                                                        <ReferenceLine className='text-xs 2xl:text-sm 3xl:text-base' y={targetStock} stroke="#FFC629" label={addThousandPoints(targetStock).toString()} strokeWidth={3} />
                                                        <ReferenceLine className='text-xs 2xl:text-sm 3xl:text-base' y={essentialInfo.reorder_point} stroke="#00B261" label={addThousandPoints(essentialInfo.reorder_point).toString()} strokeWidth={3} />
                                                        <ReferenceLine className='text-xs 2xl:text-sm 3xl:text-base' y={essentialInfo.safety_stock} stroke="#C92323" label={addThousandPoints(essentialInfo.safety_stock).toString()} strokeWidth={3} />
                                                        <Line type="monotone" dataKey="stock" stroke="#3c8dc0" activeDot={<ActiveDotMaterialInventory />} dot={<DotMaterialInventory />} label={<LabelMaterial />} />
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            ) : (
                                                <div className='flex flex-col justify-center w-full h-full gap-y-2'>
                                                    <div className='flex flex-row justify-center w-full'>
                                                        <img src={empty} alt='empty folder chart' className='w-0.50/5' />
                                                    </div>
                                                    <div className='flex flex-row justify-center'>
                                                        <p className='text-base 3xl:text-lg 4xl:text-xl mb-0 font-semibold'>{t("material.emptyChart")}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ):(
                                        <>
                                            {demands.length > 0 ? (
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <LineChart width={500} height={300} data={demands.map(item => ({...item, date: new Date(item.date).getTime()}))}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" padding={{ right: 10, left: 10 }}
                                                            tickFormatter={(date) => new Intl.DateTimeFormat('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)} 
                                                            type="number" domain={['dataMin', 'dataMax']} scale="time" className='text-xs 4xl:text-sm' />
                                                        <YAxis padding={{ top: 10, bottom: 10 }} ticks={ticksDemands} domain={[Math.min(...ticksDemands), Math.max(...ticksDemands)]} className='text-xs 4xl:text-sm' tickFormatter={(demand) => addThousandPoints(demand)} />
                                                        <Tooltip content={<TooltipMaterialDemands />} />
                                                        <Legend verticalAlign="top" content={<LegendMaterialDemand legend={legendDemand} />} />
                                                        <Line type="monotone" dataKey="demand" stroke="#3c8dc0" activeDot={<ActiveDotMaterialDemands />} dot={<DotMaterialDemands />} label={<LabelMaterial />} />
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            ) : (
                                                <div className='flex flex-col justify-center w-full h-full gap-y-2'>
                                                    <div className='flex flex-row justify-center w-full'>
                                                        <img src={empty} alt='empty folder chart' className='w-0.50/5' />
                                                    </div>
                                                    <div className='flex flex-row justify-center'>
                                                        <p className='text-base 3xl:text-lg 4xl:text-xl mb-0 font-semibold'>{t("material.emptyChart")}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col justify-center w-full h-4.5/5 gap-y-2'>
                    <div className='flex flex-row justify-center w-full'>
                        <img src={empty} alt='initial empty folder' className='w-0.75/5' />
                    </div>
                    <div className='flex flex-row justify-center'>
                        <p className='text-base 3xl:text-xl mb-0 font-semibold'>{t("material.emptyData")}</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Material