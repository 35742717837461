import React, {useEffect, useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Swal from 'sweetalert2';
import Loading from '../../pure/loading';
import emptyBox from '../../../images/empty.png'
import { postAllAnomalies, updateAnomaly, postAllWithMonths, postAllWithDateRange } from '../../../models/anomalies';
import { addAnomalies, updateAnomalies, addSearchOption, addFilterOption, addInitialMonth, addEndMonth, addInitialDate, addEndDate } from '../../../redux/anomalySlice';
import { removeAllCookies,formatCurrency } from '../../../models/generics';
import { CurrencyContext } from '../../../context/CurrencyContext';
import dayjs from 'dayjs';

const Anomaly = () => {

    const { t } = useTranslation();

    const {changeRates} = useContext(CurrencyContext)

    const loadText = "Loading Anomalies"

    const months = [
        {id:1, value:"january"}, 
        {id:2, value:"february"},
        {id:3, value:"march"}, 
        {id:4, value:"april"}, 
        {id:5, value:"may"}, 
        {id:6, value:"june"}, 
        {id:7, value:"july"}, 
        {id:8, value:"august"}, 
        {id:9, value:"september"}, 
        {id:10, value:"october"}, 
        {id:11, value:"november"}, 
        {id:12, value:"december"}
    ]

    const tableColumns = [
        { headerText: t("anomaliesModule.table.purchase_order"), width: "w-32" },
        { headerText: t("anomaliesModule.table.position"), width: "w-32" },
        { headerText: t("anomaliesModule.table.category"), width: "w-32" },
        { headerText: t("anomaliesModule.table.created_by"), width: "w-32" },
        { headerText: t("anomaliesModule.table.country"), width: "w-32" },
        { headerText: t("anomaliesModule.table.category_l1"), width: "w-32" },
        { headerText: t("anomaliesModule.table.plant"), width: "w-32" },
        { headerText: t("anomaliesModule.table.material"), width: "w-32" },
        { headerText: t("anomaliesModule.table.material_description"), width: "w-72" },
        { headerText: t("anomaliesModule.table.vendor"), width: "w-32" },
        { headerText: t("anomaliesModule.table.vendor_name"), width: "w-56" },
        { headerText: t("anomaliesModule.table.unit_measure"), width: "w-36" },
        { headerText: t("anomaliesModule.table.quantity"), width: "w-32" },
        { headerText: t("anomaliesModule.table.net_price"), width: "w-32" },
        { headerText: t("anomaliesModule.table.currency"), width: "w-32" },
        { headerText: t("anomaliesModule.table.creation_date"), width: "w-48" },
        { headerText: t("anomaliesModule.table.delivery_date"), width: "w-48" },
        { headerText: t("anomaliesModule.table.net_price_usd"), width: "w-48" },
        { headerText: t("anomaliesModule.table.anomaly_detection_date"), width: "w-48" },
        { headerText: t("anomaliesModule.table.anomaly"), width: "w-48" },
    ];

    const tableDataKeys = [
        { key: "purchase_order" },
        { key: "position" },
        { key: "acc_assignment_category" },
        { key: "po_created_by"},
        { key: "country" },
        { key: "l1_category" },
        { key: "plant" },
        { key: "material_no" },
        { key: "material_description" },
        { key: "vendor_number" },
        { key: "vendor_name" },
        { key: "unit_of_measure" },
        { key: "quantity" },
        { key: "netpr" },
        { key: "currency" },
        { key: "po_created_at" },
        { key: "po_delivered_at" },
        { key: "netpr_usd" },
        { key: "anomaly_date" },
    ];

    const money = [
        {code:"USD", locale:"en-US"},
        {code:"COP", locale:"es-CO"},
        {code:"EUR", locale:"en-ES"},
        {code:"MXN", locale:"en-MX"},
        {code:"PEN", locale:"es-PE"},
        {code:"HNL", locale:"es-HN"},
        {code:"PAB", locale:"es-PA"},
        {code:"GBP", locale:"en-GB"},
        {code:"CHF", locale:"de-CH"}
    ]

    function changeValueCurrency(value, currency) {
        const changeRateFound = changeRates === null ? "USD" : changeRates[currency]
        const localeFound = money.find((item) => item.code === currency).locale
        const formattedValue = formatCurrency(value, "standard", currency, localeFound, changeRateFound)
        return formattedValue
    }

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const anomaly = useSelector((state) => state.anomaly)

    const [loading, setLoading] = useState(true);

    const [countryFilterOptions, setCountryFilterOptions] = useState([]);
    const [initialDate, setInitialDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        if (anomaly.searchOption === "months") {
            if (anomaly.initialMonth > 0 && anomaly.endMonth > 0) {
                setDisabledButton(false)
            } else {
                setDisabledButton(true)
            }
        } else {
            if (anomaly.initialDate !== null && anomaly.endDate !== null) {
                setDisabledButton(false)
            } else {
                setDisabledButton(true)
            }
        }
    }, [anomaly.searchOption, anomaly.initialMonth, anomaly.endMonth, anomaly.initialDate, anomaly.endDate]);

    const handleInitialDateChange = (newDate) => {
        dispatch(addInitialDate(newDate.format('YYYY-MM-DD')));
    };
    
    const handleEndDateChange = (newDate) => {
        dispatch(addEndDate(newDate.format('YYYY-MM-DD')));
        setEndDate(newDate)
    };

    const changeAnomaly = (order, status) => {
        setLoading(true)
        const data = {
            "purchase_order": order,
            "anomaly_status": status,
        }
        updateAnomaly(data).then((res) => {
            dispatch(updateAnomalies(res[0]))
        }).catch((error) => {
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    removeAllCookies()
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        })
    }

    const searchAnomalies = () => {
        setLoading(true)
        if (anomaly.searchOption === "months") {
            const monsthToSend = {
                "months": [anomaly.initialMonth, anomaly.endMonth]
            }
            postAllWithMonths(monsthToSend).then((res) => {
                if (res.length > 0) {
                    dispatch(addAnomalies(res))
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: t("anomaliesModule.witout_anomalies"),
                        text: t("anomaliesModule.witout_anomalies_months"),
                        confirmButtonColor: '#FFC629',
                        allowOutsideClick: false
                    })
                }
                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        removeAllCookies()
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        confirmButtonColor: '#FFC629',
                        text: t("ApiFail")
                    })
                }
                setLoading(false)
            })
        } else {
            const datesToSend = {
                "date_start": anomaly.initialDate,
                "date_end": anomaly.endDate
            }
            postAllWithDateRange(datesToSend).then((res) => {
                if (res.length > 0) {
                    dispatch(addAnomalies(res))
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: t("anomaliesModule.witout_anomalies"),
                        text: t("anomaliesModule.witout_anomalies_dates"),
                        confirmButtonColor: '#FFC629',
                        allowOutsideClick: false
                    })
                }
                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        removeAllCookies()
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        confirmButtonColor: '#FFC629',
                        text: t("ApiFail")
                    })
                }
                setLoading(false)
            })
        }
    };

    useEffect(() => {
        if (anomaly.initialDate !== null) {
            setInitialDate(dayjs(anomaly.initialDate))
        }
    }, [anomaly.initialDate]);

    useEffect(() => {
        if (anomaly.endDate !== null) {
            setEndDate(dayjs(anomaly.endDate))
        }
    }, [anomaly.endDate]);

    useEffect(() => {
        if (anomaly.anomalies.length < 1) {
            postAllAnomalies().then((res) => {
                if (res.length > 0) {
                    dispatch(addAnomalies(res))
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: t("anomaliesModule.witout_anomalies"),
                        text: t("anomaliesModule.tableEmpty"),
                        confirmButtonColor: '#FFC629',
                        allowOutsideClick: false
                    })
                }
                setLoading(false)
            })
            .catch(error => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        removeAllCookies()
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        confirmButtonColor: '#FFC629',
                        text: t("ApiFail")
                    })
                }
                setLoading(false)
            });
        }
    }, []);

    useEffect(() => {
        if (anomaly.anomalies.length > 0) {
            const optionsCountry = [...new Set(anomaly.anomalies.map((item) => item.country))]
            setCountryFilterOptions(optionsCountry)
            setLoading(false)
        }
    }, [anomaly.anomalies]);

  return (
    <div className='wallpaper flex flex-col w-4.25/5 xl:w-4.5/5 h-full pb-1 2xl:pt-3 2xl:pb-1 4xl:pt-1 4xl:pb-1 px-1 2xl:gap-y-2'>
        {loading ? (
            <Loading text={loadText} />
        ): null}
        <div className='w-full flex flex-row justify-between h-0.50/5 2xl:h-0.25/5'>
            <div className='w-3/5 xl:w-1/2 flex items-center gap-3'>
                <div className='w-1/2 xl:w-1/4'>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="select-search-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t("anomaliesModule.select_search")}</InputLabel>
                        <Select value={anomaly.searchOption} className='!bg-white !rounded-lg !capitalize !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-search-label" id="select-search" label="Select your search"
                        onChange={(event) => dispatch(addSearchOption(event.target.value))}>
                            <MenuItem value="months">{t("anomaliesModule.months")}</MenuItem>
                            <MenuItem value="range">{t("anomaliesModule.range")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                
                {anomaly.searchOption === "months" ? (
                    <div className='flex w-3.5/5 xl:w-2/4 gap-2'>
                        <div className='w-1/2'>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="select-initial-month-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t("anomaliesModule.initial_month")}</InputLabel>
                                <Select value={anomaly.initialMonth} className='!bg-white !rounded-lg !capitalize !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-initial-month-label" id="select-initial-month" label={t("anomaliesModule.initial_month")}>
                                    <MenuItem value={0} onClick={() => dispatch(addInitialMonth(0))}><em>{t("userModule.none")}</em></MenuItem>
                                    {months.filter((item) => anomaly.endMonth === 0 || item.id < anomaly.endMonth).map((item) => (
                                        <MenuItem key={item.id} value={item.id} onClick={() => dispatch(addInitialMonth(item.id))}>
                                            {t("spendTracking."+item.value.toLowerCase())}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-1/2'>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="select-end-month-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t("anomaliesModule.end_month")}</InputLabel>
                                <Select value={anomaly.endMonth} className='!bg-white !rounded-lg !capitalize !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-end-month-label" id="select-end-month" label={t("anomaliesModule.end_month")}>
                                    <MenuItem value={0} onClick={() => dispatch(addEndMonth(0))}><em>{t("userModule.none")}</em></MenuItem>
                                    {months.filter((item) => item.id > anomaly.initialMonth).map((item) => (
                                        <MenuItem key={item.id} value={item.id} onClick={() => dispatch(addEndMonth(item.id))}>
                                            {t("spendTracking."+item.value.toLowerCase())}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                ) : (
                    <div className='flex w-3.5/5 xl:w-2/4 gap-2'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t("anomaliesModule.initial_date")}
                                value={initialDate}
                                onChange={handleInitialDateChange}
                                shouldDisableDate={(date) => date !== null && date.isAfter(endDate)}
                                slotProps={{
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            className: '!rounded-lg !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'
                                        },
                                        InputLabelProps: {
                                            sx: {
                                                transform: 'translate(8px, 10px)',
                                                lineHeight: '1.5',
                                                '&.MuiInputLabel-shrink': {
                                                    transform: 'translate(14px, -9px) scale(0.75)',
                                                },
                                            },
                                            className: '!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label={t("anomaliesModule.end_date")}
                                value={endDate}
                                onChange={handleEndDateChange}
                                shouldDisableDate={(date) => date !== null && date.isBefore(initialDate)}
                                slotProps={{
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            className: '!rounded-lg !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'
                                        },
                                        InputLabelProps: {
                                            sx: {
                                                transform: 'translate(8px, 10px)',
                                                lineHeight: '1.5',
                                                '&.MuiInputLabel-shrink': {
                                                    transform: 'translate(14px, -9px) scale(0.75)',
                                                },
                                            },
                                            className: '!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                )}
                <Button size='medium' disabled={disabledButton} onClick={() => searchAnomalies()} variant="contained" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg !text-black w-1/5 xl:w-0.75/5 4xl:w-0.50/5 !font-semibold !capitalize !bg-brand-300 hover:!bg-brand-200 disabled:!bg-brand-200'>{t("anomaliesModule.search_btn")}</Button>
            </div>
            
            <div className='w-1/5 xl:w-0.75/5 flex items-center'>
                <FormControl fullWidth size='small'>
                    <InputLabel id="select-country-filter-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t("anomaliesModule.select_country")}</InputLabel>
                    <Select value={anomaly.filterOption} className='!bg-white !rounded-lg !capitalize !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-country-label" id="select-country" label="Select Country"
                    onChange={(event) => dispatch(addFilterOption(event.target.value))}>
                        <MenuItem value=""><em>{t("userModule.none")}</em></MenuItem>
                        {countryFilterOptions.map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
        <div className='h-4.5/5 2xl:h-4.75/5 w-full overflow-y-auto overflow-x-auto'>
            {anomaly.anomalies.length > 0 ? (
                <table className='w-full table-fixed'>
                    <thead className='bg-neutral-background z-50 sticky top-0'>
                        <tr>
                            {tableColumns.map((column, index) => (
                                <th key={index+1} className={column.width}>
                                    <p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">
                                        {column.headerText}
                                    </p>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {anomaly.anomalies.filter((item) => anomaly.filterOption !== "" ? item.country === anomaly.filterOption : item.country).map((item, index) => (
                            <tr key={index+1} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                                {tableDataKeys.map((column, index) => {
                                    let content;
                                    if (column.key === "netpr") {
                                        content = changeValueCurrency(item["netpr"], item["currency"]);
                                    } else if (column.key === "netpr_usd") {
                                        content = changeValueCurrency(item["netpr_usd"], "USD");
                                    } else {
                                        content = item[column.key];
                                    }
                                    return (
                                        <td key={index + 1}>
                                            <p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">
                                                {content}
                                            </p>
                                        </td>
                                    );
                                })}
                                <td>
                                    <div className='my-3'>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id="select-search-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t("anomaliesModule.table.select.title")}</InputLabel>
                                            <Select value={item.anomaly_status} className='!bg-white !rounded-lg !capitalize !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-search-label" id="select-search" label={t("anomaliesModule.table.select.title")}
                                            onChange={(event) => changeAnomaly(item.purchase_order, event.target.value)}>
                                                <MenuItem value={""}><em>{t("userModule.none")}</em></MenuItem>
                                                <MenuItem value="process">{t("anomaliesModule.table.select.options.process")}</MenuItem>
                                                <MenuItem value="not_procede">{t("anomaliesModule.table.select.options.not_procede")}</MenuItem>
                                                <MenuItem value="cancel">{t("anomaliesModule.table.select.options.cancel")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className='flex flex-col justify-center gap-y-1 h-full w-full'>
                    <div className='flex justify-center items-center w-full'>
                        <img src={emptyBox} alt='Carga Logo' className='w-1/4' />
                    </div>
                    <div className='flex flex-row justify-center'>
                        <p className='text-base 3xl:text-lg 4xl:text-2xl mb-0 font-semibold'>{t("anomaliesModule.tableEmpty")}</p>
                    </div>
                </div>
            )}
        </div>
    </div>
  )
}

export default Anomaly