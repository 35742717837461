import { createContext, useState, useMemo } from "react";
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

export const CountryComponentContext = createContext()

/**
 * @context
 * @description This context contains the country to use on the endpoint
*/
const CountryComponentContextProvider = ({children}) => {

  /**
   * @state
   * @type {object}
   * @default {}
   * @description State to save the country
  */
  const [country, setCountry] = useState(() => {
    return Cookies.get("country_user") ? JSON.parse(Cookies.get("country_user")) : null
  });

  /**
   * @memo
   * @description Create an object that contains the current state of 'country' and a function to 
   * update it ('setCountry').
   * This object is passed as a value to the context provider to allow access and modification of the 
   * state of country from child components.
  */
  const data = useMemo(() => ({
    country, 
    setCountry
  }), [country, setCountry]);

  return (
    <CountryComponentContext.Provider value={data}>
        {children}
    </CountryComponentContext.Provider>
  )
}

CountryComponentContextProvider.propTypes = {
  children: PropTypes.node
}

export default CountryComponentContextProvider
