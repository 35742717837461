import React, {useContext} from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { PermissionContext } from '../../context/PermissionContext';

const ProtectedAdminRoutes = () => {

    /**
     * @context
     * @description Access to get the global state of the PermissionContext
    */
    const {permissions} = useContext(PermissionContext)
  
    /**
     * @condition
     * @description Validate if the permissions object has a block key with true value to enroute to block view
    */
    if (!["basic", "block", "approval"].every((perm) => permissions.includes(perm))) {
      return <Navigate to="/inventory/summary" />
    }
  
    return (
      <Outlet />
    )
  }
  
  export default ProtectedAdminRoutes