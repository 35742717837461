import React, {useEffect, useState, useRef} from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { Select, MenuItem, InputLabel, TextField, FormControl, OutlinedInput, FormHelperText, ListSubheader, ListItemText, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

const ModalCreateUpdateUser = ({countriesOptions, plantsOptions, roleOptions, dataSend, modalTitle, sendCreateUpdateUser, sendBlock}) => {

    const { t } = useTranslation();

    const dropdownCountryRef = useRef(null);
    const dropdownPlantsRef = useRef(null);

    const countriesUpdate = dataSend?.countries ? [...countriesOptions].filter((item) => dataSend.countries.includes(item.code)).map((item) => item.code) : [];
    const plantsUpdate = dataSend?.plants ? [...plantsOptions].sort((a, b) => a.country - b.country).filter((item) => dataSend.plants.map((obj) => obj).includes(item.id)).map((item) => item.id) : [];

    const [openCountry, setOpenCountry] = useState(false);
    const [openPlants, setOpenPlants] = useState(false);

    const handleClickOutsideForCountry = (event) => {
        if (dropdownCountryRef.current && !dropdownCountryRef.current.contains(event.target)) {
            setOpenCountry(false);
        }
    };

    const handleClickOutsideForPlants = (event) => {
        if (dropdownPlantsRef.current && !dropdownPlantsRef.current.contains(event.target)) {
            setOpenPlants(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideForCountry);
        return () => {
          document.removeEventListener("mousedown", handleClickOutsideForCountry);
        };
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideForPlants);
        return () => {
          document.removeEventListener("mousedown", handleClickOutsideForPlants);
        };
    }, []);

    const { register, watch, setValue, formState: {isValid, errors}, trigger } = useForm({
        mode: 'onChange',
        defaultValues: {
          firstname: dataSend?.firstname || "",
          lastname: dataSend?.lastname || "",
          role: dataSend?.role || "",
          email: dataSend?.email || "",
          countries: countriesUpdate,
          plants: plantsUpdate
        }
    });

    useEffect(() => {
        trigger();
    }, [trigger]);

    const roleValue = watch("role");
    const countriesValue = watch("countries")
    const plantsValue = watch("plants")

    const handleCountryOption = (event) => {
        const { target: { value },} = event;
        value.sort((a, b) => a.id - b.id)
        const newPlants = [...plantsOptions].sort((a, b) => a.country - b.country).filter((item) => countriesOptions.filter((obj) => value.includes(obj.code)).map((obj) => obj.id).includes(item.country)).filter((item) => watch("plants").includes(item.id)).map((item) => item.id)
        const newCountries = typeof value === 'string' ? value.split(',') : value
        setValue("countries", newCountries)
        sendCreateUpdateUser("countries", newCountries)
        setValue("plants", newPlants)
        sendCreateUpdateUser("plants", newPlants)
        trigger(["countries", "plants"])
    };

    const handlePlantOption = (event) => {
        const { target: { value },} = event;
        let valueOrdered = []
        const idCountry = value.find(item => typeof item === 'number');
        if (typeof idCountry === 'number') {
            const elementsByCountry = [...plantsOptions].sort((a, b) => a.country - b.country).filter((item) => item.country === idCountry).map((item) => item.id)
            const validateElements = elementsByCountry.every((item) => plantsValue.includes(item))
            if (validateElements) {
                valueOrdered = [...plantsValue].filter(elemento => ![...plantsOptions].filter((item) => item.country === idCountry).map((item) => item.id).includes(elemento))
            } else {
                let valuesSelected = [...plantsOptions].sort((a, b) => a.country - b.country).filter((item) => item.country === idCountry).map((item) => item.id)
                valueOrdered = [...plantsValue, ...valuesSelected]
            }
        } else if (value.includes("all")) {
            if (plantsOptions.length === plantsValue.length) {
                valueOrdered = []
            } else {
                valueOrdered = [...plantsOptions].sort((a, b) => a.country - b.country).map((plant) => plant.id)
            }
        } else {
            valueOrdered = [...plantsOptions].sort((a, b) => a.country - b.country).filter((item) => value.includes(item.id)).map((item) => item.id)
        }
        const newPlants = typeof valueOrdered === 'string' ? valueOrdered.split(',') : valueOrdered
        const filterPlant = [...plantsOptions].filter((plant) => newPlants.includes(plant.id))
        const countrySave = [...countriesOptions].filter((item) => filterPlant.some((plant) => plant.country === item.id)).map((item) => item.code)
        setValue("plants", newPlants)
        setValue("countries", countrySave)
        sendCreateUpdateUser("plants", newPlants)
        sendCreateUpdateUser("countries", countrySave)
    };

    useEffect(() => {
        trigger(["countries", "plants"])
    }, [watch("countries"), watch("plants")]);

    const validatePlants = (value, countries) => {
        const selectedPlants = value;
        const filterCountries = [...countriesOptions].filter((item) => countries.includes(item.code)).map((item) => item.id)
        const filterPlantsCountry = [...plantsOptions]
        .filter((item) => filterCountries.includes(item.country))
        .reduce((acc, item) => {
            const existingCountryIndex = acc.findIndex(obj => obj.countryId === item.country);
            if (existingCountryIndex !== -1) {
                acc[existingCountryIndex].plants.push(item);
            } else {
                acc.push({
                  countryId: item.country,
                  plants: [item]
                });
            }
            return acc;
        }, [])
        const hasPlantsForEachCountry = filterPlantsCountry.every((item) =>
          item.plants.some((plant) => selectedPlants.includes(plant.id))
        );
        return hasPlantsForEachCountry || t("userModule.formError.hasAllPlants");
    };

    const hanleInputs = (e, key) => {
        let input = e.target.value;
        input = input.trimStart();
        const words = input.split(/\s+/);
        input = words.join(' ');
        setValue(key, input)
        sendCreateUpdateUser(key, input);
        trigger(key)
    }

    const handleEmail = (e) => {
        const trimmedValue = e.target.value.trim();
        setValue("email", trimmedValue)
        sendCreateUpdateUser("email", trimmedValue);
        trigger("email")
    }

    function firstElement(element) {
        const condition = item => item.country === element.country;
        const firstElement = [...plantsOptions].sort((a, b) => a.country - b.country).find(condition);
        return firstElement === undefined ? false : firstElement.id === element.id;
    }

    useEffect(() => {
        sendBlock(isValid)
    }, [isValid]);

  return (
    <form>
        <div className='flex flex-col w-full h-full gap-y-3'>
            <div className='flex flex-row gap-4 w-full'>
                <TextField id='input-first' {...register("firstname", { required: t("userModule.formError.requiredFirstname") })}
                    sx={{mt: 1}}
                    size='small' 
                    error={!!errors.firstname}
                    className='!w-1/3'
                    InputProps={{className: '!rounded-lg !bg-white', "data-testid": "firstname-input" }}
                    InputLabelProps={{
                        sx: {"&.Mui-focused": { fontWeight: 600 }, ...(watch("firstname") && { fontWeight: 600 })}
                    }}
                    FormHelperTextProps={{sx: {margin: 0, fontWeight: 600}}}
                    helperText={errors.firstname ? errors.firstname.message : ""}
                    label={t("userModule.firstname")} 
                    onChange={(e) => hanleInputs(e, "firstname")} 
                />
                <TextField id='input-second' {...register("lastname", { required: t("userModule.formError.requiredLastname") })}
                    sx={{mt: 1}}
                    size='small' 
                    error={!!errors.lastname}
                    className='!w-1/3'
                    InputProps={{className: '!capitalize !rounded-lg !bg-white', "data-testid": "lastname-input"}}
                    InputLabelProps={{
                        sx: {"&.Mui-focused": { fontWeight: 600 }, ...(watch("lastname") && { fontWeight: 600 })}
                    }}
                    FormHelperTextProps={{sx: {margin: 0, fontWeight: 600}}}
                    helperText={errors.lastname ? errors.lastname.message : ""}
                    label={t("userModule.lastname")} onChange={(e) => hanleInputs(e, "lastname")} 
                />
                <div className='w-1/3'>
                    <FormControl sx={{mt: 1}} fullWidth size='small' error={!!errors.role}>
                        <InputLabel id="select-role-label" sx={{'&.Mui-focused': { fontWeight: 600 }, ...(roleValue && { fontWeight: 600 }) }}>{t("userModule.select_role")}</InputLabel>
                        <Select {...register("role", { required: t("userModule.formError.requiredRole") })} value={roleValue}
                            data-testid="role-select-modal"
                            labelId="select-role-label"
                            id="select-role"
                            label={t("userModule.select_role")}
                            onChange={(e) => {setValue("role", e.target.value); sendCreateUpdateUser("role", e.target.value); trigger("role");}}
                            className='!bg-white !capitalize !rounded-lg'
                        >
                            <MenuItem value=""><em>{t("userModule.none")}</em></MenuItem>
                            {roleOptions.map((item) => (
                                <MenuItem key={item} value={item} className='!capitalize'>{(item).replace('_', ' ')}</MenuItem>
                            ))}
                        </Select>
                        {errors.role && <FormHelperText className='!m-0 !font-semibold'>{errors.role.message}</FormHelperText>}
                    </FormControl>
                </div>
            </div>
            <TextField {...register("email", { required: t("userModule.formError.requiredEmail"), 
                pattern: {
                    value: /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/,
                    message: 'Email Invalid'
                } })}
                disabled={modalTitle === "Update"}
                size='small' 
                error={!!errors.email}
                className='!w-full '
                InputProps={{"data-testid": "email-input", className: `!capitalize !rounded-lg ${modalTitle === "Update" ? "bg-neutral-200" : "!bg-white"}`}}
                InputLabelProps={{
                    sx: {"&.Mui-focused": { fontWeight: 600 }, ...(watch("email") && { fontWeight: 600 })}
                }}
                FormHelperTextProps={{sx: {margin: 0, fontWeight: 600}}}
                helperText={errors.email ? errors.email.message : ""}
                label={t("userModule.email")} onChange={(e) => handleEmail(e)}
            />
            <FormControl ref={dropdownCountryRef} fullWidth size='small' error={!!errors.countries}>
                <InputLabel id="select-country-label" sx={{'&.Mui-focused': { fontWeight: 600 }, ...(countriesValue.length > 0 && { fontWeight: 600 }) }}>{t("userModule.select_countries")}</InputLabel>
                <Select {...register("countries", { required: t("userModule.formError.requiredCountries") })} value={countriesValue}
                    multiple
                    data-testid="countries-select-modal"
                    labelId="select-country-label"
                    id="select-country"
                    label={t("userModule.select_countries")}
                    onChange={(event) => handleCountryOption(event)}
                    open={openCountry}
                    onOpen={() => setOpenCountry(true)}
                    onClose={() => setOpenCountry(false)}
                    input={<OutlinedInput label="Select Countries" />} 
                    renderValue={(selected) => countriesOptions.filter((item) => selected.includes(item.code)).map((item) => item.name).join(', ')}
                    className='!bg-white !capitalize !rounded-lg'
                    MenuProps={{
                        disableAutoFocusItem: true,
                        PaperProps: {
                            onMouseDown: (event) => {
                                event.stopPropagation();
                            },
                        },
                    }}
                >
                    {countriesOptions.map((item) => (
                        <MenuItem key={item.id} value={item.code}>
                            <Checkbox checked={countriesValue.includes(item.code)} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ))}
                </Select>
                {errors.countries && <FormHelperText className='!m-0 !font-semibold'>{errors.countries.message}</FormHelperText>}
            </FormControl>
            <FormControl ref={dropdownPlantsRef} fullWidth size='small' error={!!errors.plants}>
                <InputLabel id="select-plant-label" sx={{'&.Mui-focused': { fontWeight: 600 }, ...(plantsValue.length > 0 && { fontWeight: 600 }) }}>{t("userModule.select_plants")}</InputLabel>
                <Select {...register("plants", { required: t("userModule.formError.requiredPlants"), validate: (value) => validatePlants(value, countriesValue)})} value={plantsValue}
                    multiple
                    data-testid="plants-select-modal"
                    onChange={(event) => handlePlantOption(event)}
                    open={openPlants}
                    onOpen={() => setOpenPlants(true)}
                    onClose={() => setOpenPlants(false)}
                    input={<OutlinedInput label="Select Plant" />}
                    renderValue={(selected) => selected.join(', ')}
                    className='!bg-white !rounded-lg'
                    labelId="select-plant-label"
                    id="select-plant"
                    label={t("userModule.select_plants")}
                    MenuProps={{
                        disableAutoFocusItem: true,
                        PaperProps: {
                            onMouseDown: (event) => {
                                event.stopPropagation();
                            },
                        },
                    }}
                >
                    <MenuItem value={"all"}>
                        <Checkbox checked={plantsValue.length === plantsOptions.length} />
                        <ListItemText primary={t("userModule.selectAll")} />
                    </MenuItem>
                    {[...plantsOptions].sort((a, b) => {
                        const countryNameA = countriesOptions.find(countryItem => countryItem.id === a.country)?.name || '';
                        const countryNameB = countriesOptions.find(countryItem => countryItem.id === b.country)?.name || '';
                        return countryNameA.localeCompare(countryNameB)
                    }).reduce((acc, item) => {
                        if (firstElement(item)) {
                            acc.push(<ListSubheader key={item.country} className='!flex !text-base !h-10 !items-center !font-semibold'>{countriesOptions.filter((obj) => obj.id === item.country).map((item) => item.name)}</ListSubheader>)
                            acc.push(
                                <MenuItem key={`all-${item.country}`} value={item.country}>
                                    <Checkbox checked={(() => {
                                            const elementsByCountry = [...plantsOptions]
                                            .sort((a, b) => a.country - b.country)
                                            .filter((plant) => plant.country === item.country)
                                            .map((item) => item.id);
                                            const validateElements = elementsByCountry.every((item) => plantsValue.includes(item));
                                            return validateElements;
                                        })()}    
                                    />
                                    <ListItemText primary={`${t("userModule.selectAllPlants")} ${countriesOptions.filter((obj) => obj.id === item.country).map((item) => item.name)}`} />
                                </MenuItem>
                            )
                        }
                        acc.push(
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={plantsValue.includes(item.id)} />
                                <ListItemText primary={item.id} />
                            </MenuItem>
                        )
                        return acc;
                    }, []).map((item) => item)}
                </Select>
                {errors.plants && <FormHelperText className='!m-0 !font-semibold'>{errors.plants.message}</FormHelperText>}
            </FormControl>
        </div>
    </form>
  )
}

ModalCreateUpdateUser.propTypes = {
    countriesOptions: PropTypes.array, 
    plantsOptions: PropTypes.array, 
    roleOptions: PropTypes.array, 
    dataSend: PropTypes.any, 
    modalTitle: PropTypes.string, 
    sendCreateUpdateUser: PropTypes.func,
    sendBlock: PropTypes.func
}

export default ModalCreateUpdateUser
