import React, {useContext} from 'react'
import { formatCurrency } from '../../../models/generics';
import { CurrencyContext } from '../../../context/CurrencyContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TooltipNegoVendor = ({ active, payload, label }) => {

    const { t } = useTranslation();

    const {currency, locale, changeRateSelected} = useContext(CurrencyContext)

    if (active && payload?.length) {
        return (
            <div className="flex flex-col items-center p-2 rounded-lg bg-white">
                <p className="font-semibold mb-0">{`${t("tooltipChart.vendor")}: ${label}`}</p>
                <p className="font-semibold mb-0 text-start">{`${t("tooltipChart.value")}: ${formatCurrency(payload[0].value, "standard", currency, locale, changeRateSelected)}`}</p>
            </div>
        );
    }
}

TooltipNegoVendor.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string
}

export default TooltipNegoVendor
