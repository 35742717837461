// Import the resources
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoutes = () => {

  /**
   * @condition
   * @description Validate if the array of plants has a quantity less than 1 to enroute to initial view
  */
  if (!Cookies.get("token")) {
    return <Navigate to="/" />
  }

  return (
    <Outlet />
  )
}

export default ProtectedRoutes
