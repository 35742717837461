import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, TextField, Select, MenuItem, InputLabel, FormControl, ListSubheader } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import Loading from '../../pure/loading';
import { getUsers, getAllPlants, getAllCountries, getAllRoles, postUser, putUser } from '../../../models/users';
import { useSelector, useDispatch } from 'react-redux';
import { addUserList, addPlants, addCountries, addRoles, updateUserList, updateUserByEmail } from '../../../redux/userSlice';
import ModalUserDetails from '../../pure/modals/modalUserDetails';
import ModalCreateUpdateUser from '../../pure/modals/modalCreateUpdateUser';
import '../../../styles/user_style.css'
import emptyBox from '../../../images/empty.png'
import { removeAllCookies } from '../../../models/generics';

const Users = () => {

  const { t } = useTranslation();

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const [userSearch, setUserSearch] = useState("");
  const [countrySearch, setCountrySearch] = useState("");
  const [plantSearch, setPlantSearch] = useState("");
  const [roleSearch, setRoleSearch] = useState("");
  const [zoneSearch, setZoneSearch] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [modalDetails, setModalDetails] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [modalCreateUpdate, setModalCreateUpdate] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [dataSend, setDataSend] = useState("");
  const [blockApply, setBlockApply] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadText, setLoadText] = useState("");

  useEffect(() => {
    setCountryOptions(user.countries.filter((item) => zoneSearch === "" || item.zone === zoneSearch))
  }, [user.countries, zoneSearch]);
    
  const openModalDetails = (item) => {
    setUserDetails(item)
    setModalDetails(!modalDetails)
  };

  const openModalCreate = () => {
    setModalTitle("Create")
    setDataSend("")
    setModalCreateUpdate(!modalCreateUpdate)
  };

  const openModalUpdate = (userData) => {
    setModalTitle("Update")
    const countries = userData.countries_with_plants.map(item => item.country_code);
    
    const plants = userData.countries_with_plants.map(item => item.plants).flat();

    const result = {
        ...userData,
        countries: countries,
        plants: plants
    };
    delete result.countries_with_plants
    setDataSend(result)
    setModalCreateUpdate(!modalCreateUpdate)
  };

  const filteredUsers = user.userList.filter(item =>
    (item.firstname.toLowerCase().includes(userSearch.toLowerCase()) ||
        item.lastname.toLowerCase().includes(userSearch.toLowerCase()) ||
        item.email.toLowerCase().includes(userSearch.toLowerCase())) && (roleSearch === "" || item.role === roleSearch) &&
        item.countries_with_plants.some(obj => zoneSearch === "" || obj.zone.includes(zoneSearch)) &&
        item.countries_with_plants.some(obj => countrySearch === "" || obj.country_code.includes(countrySearch.code)) &&
        item.countries_with_plants.some(obj => obj.plants.some(plant => plant.includes(plantSearch)))
  );

  useEffect(() => {
    if (user.plants.length < 1 && user.countries.length < 1 && user.userList.length < 1 && user.roles.length < 1) {
      setLoading(true)
      setLoadText("Loading the users")
      const users = getUsers()
      const plants = getAllPlants()
      const countries = getAllCountries()
      const roles = getAllRoles()
      Promise.all([users, plants, countries, roles]).then(([resUsers, resPlants, resCountries, resRoles]) => {
        if (resUsers.length > 0 && resPlants.length > 0 && resCountries.length > 0 && resRoles.length > 0) {
          dispatch(addUserList(resUsers))
          dispatch(addPlants(resPlants))
          dispatch(addCountries(resCountries.sort((a, b) => a.name.localeCompare(b.name))))
          dispatch(addRoles(resRoles))
        } else {
          dispatch(addUserList([]))
          dispatch(addPlants([]))
          dispatch(addCountries([]))
          dispatch(addRoles([]))

          if (resUsers.length < 1 && resPlants.length < 1 && resCountries.length < 1 && resRoles.length < 1) {
            Swal.fire({
              icon: 'info',
              title: "The users, plants and countries lists are empty",
              text: "Please try later.",
              confirmButtonColor: '#FFC629',
              confirmButtonText: "Ok",
              allowOutsideClick: false
            })
          } else if (resUsers.length < 1) {
            Swal.fire({
              icon: 'info',
              title: "The Users List is empty",
              text: "Please try later.",
              confirmButtonColor: '#FFC629',
              confirmButtonText: "Ok",
              allowOutsideClick: false
            })
          } else if (resPlants.length < 1) {
            Swal.fire({
              icon: 'info',
              title: "The Plants List is empty",
              text: "Please try later.",
              confirmButtonColor: '#FFC629',
              confirmButtonText: "Ok",
              allowOutsideClick: false
            })
          } else if (resCountries.length < 1) {
            Swal.fire({
              icon: 'info',
              title: "The Countries List is empty",
              text: "Please try later.",
              confirmButtonColor: '#FFC629',
              confirmButtonText: "Ok",
              allowOutsideClick: false
            })
          } else if (resRoles.length < 1) {
            Swal.fire({
              icon: 'info',
              title: "The Roles List is empty",
              text: "Please try later.",
              confirmButtonColor: '#FFC629',
              confirmButtonText: "Ok",
              allowOutsideClick: false
            })
          }
        }
        setLoading(false)
      }).catch(error => {
        if (error.response.status === 401) {
          Swal.fire({
            icon: 'error',
            title: "Session Finished",
            text: "Please LogIn again to continue",
            confirmButtonColor: '#FFC629',
            confirmButtonText: "LogIn",
            allowOutsideClick: false
          }).then(() => {
            removeAllCookies()
            navigate("/")
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: '¡Oops!',
            text: "We have an error, please try again later.",
            confirmButtonColor: '#FFC629'
          })
        }
        setLoading(false)
      })
    }
  }, []);

  const handleZoneSelect = (zone) => {
    if (zone !== "") {
      setZoneSearch(zone)
    } else {
      setZoneSearch("")
    }
    setCountrySearch("")
    setPlantSearch("")
  }

  const handleCountrySelect = (country) => {
    if (country !== "") {
      setCountrySearch(country)
    } else {
      setCountrySearch("")
    }
    setPlantSearch("")
  }

  const sendCreateUpdateUser = (key, value) => {
    setDataSend(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }

  const sendBlock = (allow) => {
    setBlockApply(allow)
  }

  const applyPostPut = () => {
    const data = {
      "first_name": dataSend.firstname.trim().split(/\s+/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
      "last_name": dataSend.lastname.trim().split(/\s+/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
      "email": dataSend.email,
      "role": dataSend.role,
      "plants": dataSend.plants,
      "countries": dataSend.countries
    }
    setModalCreateUpdate(!modalCreateUpdate)
    if (modalTitle === "Create") {
      setLoading(true)
      setLoadText("Creating the user")
      postUser(data).then((res) => {
        Swal.fire({
          icon: 'success',
          title: "¡Congratulations!",
          text: "The user has been created successfully",
          confirmButtonColor: '#FFC629',
          confirmButtonText: "Ok",
          allowOutsideClick: false
        })
        dispatch(updateUserList(res.data))
        setLoading(false)
      }).catch(error => {
        switch (error.response.status) {
          case 401:
            Swal.fire({
              icon: 'error',
              title: t("finishedSessionTitle"),
              text: t("finishedSessionText"),
              confirmButtonColor: '#FFC629',
              confirmButtonText: "LogIn",
              allowOutsideClick: false
            }).then(() => {
              removeAllCookies()
              navigate("/")
            })
            break;
          case 400:
              Swal.fire({
                icon: 'error',
                title: t("userModule.emailErrorTittle"),
                text: t("userModule.emailErrorText"),
                confirmButtonColor: '#FFC629',
                allowOutsideClick: false
              })
              break;
          default:
            Swal.fire({
              icon: 'error',
              title: '¡Oops!',
              text: t("ApiFail"),
              confirmButtonColor: '#FFC629'
            })
            break;
        }
        setLoading(false)
      })
    } else {
      setLoading(true)
      setLoadText("Updating the user")
      putUser(data).then((res) => {
        Swal.fire({
          icon: 'success',
          title: "¡Congratulations!",
          text: "The user has been updated successfully",
          confirmButtonColor: '#FFC629',
          confirmButtonText: "Ok",
          allowOutsideClick: false
        })
        dispatch(updateUserByEmail(res.data))
        setLoading(false)
      }).catch(error => {
        if (error.response.status === 401) {
          Swal.fire({
            icon: 'error',
            title: "Session Finished",
            text: "Please LogIn again to continue",
            confirmButtonColor: '#FFC629',
            confirmButtonText: "LogIn",
            allowOutsideClick: false
          }).then(() => {
            removeAllCookies()
            navigate("/")
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: '¡Oops!',
            text: "We have an error, please try again later.",
            confirmButtonColor: '#FFC629'
          })
        }
        setLoading(false)
      })
    }
  }

  function firstElement(element) {
    const condition = item => item.country === element.country;
    const firstElement = [...user.plants].sort((a, b) => a.country - b.country).find(condition);
    return firstElement === undefined ? false : firstElement.id === element.id;
  }

  return (
    <div className='wallpaper flex flex-col w-4.5/5 h-full py-1 2xl:py-2 px-1 2xl:gap-y-2'>
      <Dialog open={modalDetails} classes={{paper: "!max-w-4.25/5 !w-4.25/5 2xl:!max-w-3.5/5 2xl:!w-3.5/5 4xl:!max-w-1/2 4xl:!w-1/2 !h-full xl:!h-4/5 4xl:!h-1/2 !bg-neutral-400 !rounded-lg"}}>
        <DialogTitle className='flex justify-between w-full items-center !py-1'>
          <div className='flex justify-start w-1/2'>
            <p className='mb-0 font-semibold text-neutral-200'>{t("userModule.user_details")}</p>
          </div>
          <IconButton color="inherit" onClick={() => setModalDetails(!modalDetails)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='h-4.5/5'>
          <ModalUserDetails details={userDetails} />
        </DialogContent>
        <DialogActions className='p-2'>
          <button onClick={() => setModalDetails(!modalDetails)} className='border-none bg-brand-300 hover:bg-brand-200 disabled:bg-transparent disabled:border-neutral-200 disabled:border disabled:border-solid disabled:text-neutral-200 w-0.75/5 rounded font-semibold py-2'>{t("userModule.close")}</button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalCreateUpdate} classes={{paper: "!max-w-4.25/5 !w-4.25/5 2xl:!max-w-3.5/5 2xl:!w-3.5/5 4xl:!max-w-1/2 4xl:!w-1/2 !bg-neutral-400 !rounded-lg"}}>
        <DialogTitle className='flex justify-between w-full items-center !py-1'>
          <div className='flex justify-start w-1/2'>
            <p className='mb-0 font-semibold text-neutral-200'>{modalTitle === "Create" ? t("userModule.create_user") : t("userModule.update_user") }</p>
          </div>
          <IconButton color="inherit" onClick={() => setModalCreateUpdate(!modalCreateUpdate)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='h-4.5/5'>
          <ModalCreateUpdateUser countriesOptions={user.countries} plantsOptions={user.plants} 
          roleOptions={user.roles} dataSend={dataSend} modalTitle={modalTitle}
          sendCreateUpdateUser={sendCreateUpdateUser} sendBlock={sendBlock} />
        </DialogContent>
        <DialogActions className='!pt-1'>
          <button disabled={!blockApply} onClick={() => applyPostPut()} className='border-none bg-brand-300 hover:bg-brand-200 disabled:bg-transparent disabled:border-neutral-200 disabled:border disabled:border-solid disabled:text-neutral-200 w-0.75/5 rounded font-semibold py-2'>{t("applyButton")}</button>
        </DialogActions>
      </Dialog>
      {loading ? (
        <Loading text={loadText} />
      ): null}
      <div className='w-full flex flex-row justify-between h-0.50/5 2xl:h-0.25/5'>
        <div className='w-0.50/5 h-full flex items-center'>
          <Button variant="contained" onClick={() => openModalCreate()} className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg !text-black w-full !font-semibold !capitalize !bg-brand-300 hover:!bg-brand-200 disabled:!bg-brand-200'>{t("userModule.create_user")}</Button>
        </div>
        <div className='w-4.5/5 h-full flex flex-row justify-end gap-2 2xl:gap-4 items-center'>
          <TextField onChange={(event) => setUserSearch(event.target.value)} 
            size='small' 
            disabled={user.userList.length < 1}
            className='!w-0.75/5'
            InputProps={{className: '!rounded-lg !bg-white !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'}} 
            InputLabelProps={{className: '!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'}}
            label={t("userModule.search_user")} 
          />
          <div className='w-1/5 2xl:w-0.75/5'>
            <FormControl fullWidth size="small">
              <InputLabel id="select-role-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t("userModule.search_role")}</InputLabel>
              <Select value={roleSearch} onChange={(event) => setRoleSearch(event.target.value)} className='!bg-white !rounded-lg !capitalize !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-role-label" id="select-role" label="Search Role">
                <MenuItem value=""><em>{t("userModule.none")}</em></MenuItem>
                {user.roles.map((item) => (
                  <MenuItem key={item} value={item} className='!capitalize'>{(item).replace('_', ' ')}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='w-1/5 2xl:w-0.75/5'>
            <FormControl fullWidth size="small">
              <InputLabel id="select-role-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t("userModule.search_zone")}</InputLabel>
              <Select value={zoneSearch} onChange={(event) => handleZoneSelect(event.target.value)} className='!bg-white !rounded-lg !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-role-label" id="select-role" label="Search Zone">
                <MenuItem value=""><em>{t("userModule.none")}</em></MenuItem>
                <MenuItem value={"MAZ"}>MAZ</MenuItem>
                <MenuItem value={"SAZ"}>SAZ</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='w-1/5 2xl:w-0.75/5'>
            <FormControl fullWidth size="small">
              <InputLabel id="select-country-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t("userModule.search_country")}</InputLabel>
              <Select disabled={filteredUsers.length < 1} value={countrySearch} onChange={(event) => handleCountrySelect(event.target.value)} className='!bg-white !rounded-lg !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-country-label" id="select-country" label="Search Country">
                <MenuItem value=""><em>{t("userModule.none")}</em></MenuItem>
                {countryOptions.map((item) => (
                  <MenuItem key={item.id} value={item}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='w-1/5 2xl:w-0.75/5'>
            <FormControl fullWidth size="small">
              <InputLabel id="select-plant-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t("userModule.search_plant")}</InputLabel>
              <Select value={plantSearch} disabled={filteredUsers.length < 1} onChange={(event) => setPlantSearch(event.target.value)} className='!bg-white !rounded-lg !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-plant-label" id="select-plant" label="Search Plant">
                <MenuItem value=""><em>{t("userModule.none")}</em></MenuItem>
                {[...user.plants].sort((a, b) => a.country - b.country).filter((item) => countryOptions.map((obj) => obj.id).includes(item.country)).filter((item) => countrySearch === "" || countrySearch.id === item.country)
                .reduce((acc, item) => {
                  if (firstElement(item)) {
                    acc.push(<ListSubheader key={item.country} className='!flex !text-base !h-10 !items-center !font-semibold'>{countryOptions.filter((obj) => obj.id === item.country).map((item) => item.name)}</ListSubheader>)
                  }

                  acc.push(
                    <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem>
                  )
                  return acc;
                }, []).map((item) => item)}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className='h-4.5/5 2xl:h-4.75/5 overflow-y-auto'>
        {filteredUsers.length > 0 ? (
          <table className='w-full'>
            <thead className='bg-neutral-background z-50 sticky top-0'>
              <tr>
                <th><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("userModule.firstname")}</p></th>
                <th><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("userModule.lastname")}</p></th>
                <th><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("userModule.email")}</p></th>
                <th><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("userModule.role")}</p></th>
                <th><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("userModule.plants_details")}</p></th>
                <th><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("userModule.edit")}</p></th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((item, index) => (
                <tr key={index + 1} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                  <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.firstname}</p></td>
                  <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.lastname}</p></td>
                  <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.email}</p></td>
                  <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center capitalize">{(item.role).replace('_', ' ')}</p></td>
                  <td>
                    <div className='flex justify-center'>
                      <IconButton onClick={() => openModalDetails(item)}><VisibilityIcon className='w-full h-full' /></IconButton>
                    </div>
                  </td>
                  <td>
                    <div className='flex justify-center'>
                      <IconButton onClick={() => openModalUpdate(item)}><EditIcon className='w-full h-full' /></IconButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className='flex flex-col justify-center gap-y-1 h-full w-full'>
            <div className='flex justify-center items-center w-full'>
                <img src={emptyBox} alt='Carga Logo' className='w-1/4' />
            </div>
            <div className='flex flex-row justify-center'>
                <p className='text-base 3xl:text-lg 4xl:text-2xl mb-0 font-semibold'>{t("userModule.tableEmpty")}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Users
