import React, {useState, useEffect, useContext} from 'react'
import { formatCurrency } from '../../models/generics';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../context/CurrencyContext';
import emptyBox from '../../images/empty.png'
import PropTypes from 'prop-types';

/**
 * @component
 * @description This component show the table in the intercompany sales view
*/
const TableIntercompany = ({selected, data, sendChecked, sendCheckeds}) => {

    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    const {currency, locale, changeRateSelected} = useContext(CurrencyContext)

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to know if all rows shown are checked 
    */
    const [allChecked, setAllChecked] = useState(false);

    /**
     * @effect
     * @description This effect is executed when the data prop is updated. 
     * Updates the state todosMarcados because verify that all elements have the selected field in true or false
    */
    useEffect(() => {
        const allCheckeds = data.some(item => item.selected === false);
        if (allCheckeds) {
            setAllChecked(false)
        } else {
            setAllChecked(true)
        }
    }, [data]);

    /**
     * @function
     * @name handleCheckboxChange
     * @description Function to update the key approved value of the register in the intercompany table
     * @param {object} element - Register selected
    */
    const handleCheckboxChange = (element) => {
        const copyElement = {...element};
        copyElement.selected = !copyElement.selected;
        sendChecked(copyElement)
    };

    /**
     * @function
     * @name handleAllCheckbox
     * @description Function to update the key approved value in all register in the intercompany table
    */
    const handleAllCheckbox = () => {
        const newArray = data.map((item) => ({...item, selected: !allChecked}));
        sendCheckeds(newArray)
    }

    return (
        <>
            {/* Space for the table */}
            {data.length > 0 ? (
                <table className='w-full'>
                    <thead className='bg-neutral-background z-50 sticky top-0'>
                        <tr>
                            {selected !== "all" ? (
                                <th><p className="mb-0 py-2 text-center text-xs 2xl:text-base 4xl:text-lg">{t("intercompanySales.table.columns.plantaEntrega")}</p></th>
                            ):null}
                            <th><p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">{t("intercompanySales.table.columns.skuCode")}</p></th>
                            <th><p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">{t("intercompanySales.table.columns.description")}</p></th>
                            <th><p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">{t("intercompanySales.table.columns.providerPlanner")}</p></th>
                            <th><p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">{t("intercompanySales.table.columns.providerCriticality")}</p></th>
                            <th><p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">{t("intercompanySales.table.columns.plantaCompra")}</p></th>
                            <th><p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">{t("intercompanySales.table.columns.stockMover")}</p></th>
                            <th><p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">{t("intercompanySales.table.columns.precioTotal")}</p></th>
                            <th><p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">{t("intercompanySales.table.columns.distance")}</p></th>
                            {selected === "all" ? (
                                <th>
                                    <div className='flex justify-center w-full'>
                                        <input type='checkbox' className="w-4 h-4 2xl:w-5 2xl:h-5" checked={allChecked} onChange={handleAllCheckbox} />
                                    </div>
                                </th>
                            ):null}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={item.id} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                                {selected !== "all" ? (
                                    <td><p className="py-2 text-xs 2xl:text-base 4xl:text-lg text-center">{item.delivery_plant}</p></td>
                                ):null}
                                <td><p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.material}</p></td>
                                <td><p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.description}</p></td>
                                <td><p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.provider_planner}</p></td>
                                <td><p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.provider_criticality !== null ? "Yes":"No"}</p></td>
                                <td><p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.purchase_plant}</p></td>
                                <td><p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.stock_to_move}</p></td>
                                <td><p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{formatCurrency(item.unit_price, "standard", currency, locale, changeRateSelected)}</p></td>
                                <td><p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.distance_km} Km</p></td>
                                {selected === "all" ? (
                                    <td>
                                        <div className='flex justify-center w-full'>
                                            <input type='checkbox' className="w-4 h-4 2xl:w-5 2xl:h-5" onChange={() => handleCheckboxChange(item)} checked={item.selected === true} />
                                        </div>
                                    </td>
                                ):null}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ):(
                <div className='flex flex-col justify-center gap-y-1 h-full w-full'>
                    <div className='flex justify-center items-center w-full'>
                        <img src={emptyBox} alt='Carga Logo' className='w-1/4' />
                    </div>
                    <div className='flex flex-row justify-center'>
                        <p className='text-base 3xl:text-lg 4xl:text-xl mb-0 font-semibold'>{selected === "all" ? t("intercompanySales.plantEmpty") : t("intercompanySales.selectEmpty")}</p>
                    </div>
                </div>
            )}
        </>   
    )
}

TableIntercompany.propTypes = {
    selected: PropTypes.string, 
    data: PropTypes.array, 
    sendChecked: PropTypes.func, 
    sendCheckeds: PropTypes.func
}

export default TableIntercompany