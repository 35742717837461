import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

/**
 * @function getPolicy
 * @description Sends a GET request to the get-policy endpoint of the API.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request, the data is displayed on these objects:
 * responseData, uniquePlannersClean and uniquePlantsClean
*/
function getPolicy(country, plant, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/get-policy/${country.normalCountry}/?zone=${zone}&plant=${plant}&${apiParam}`, config).then(res => {
        const responseData = res.data;
        return {responseData};
    });
}

/**
 * @function getPlannersPlants
 * @description Sends a GET request to the get-ini-pla endpoint of the API.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request, the data is displayed on this objects: planners
*/
function getPlannersPlants(country, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/get-ini-pla/${country.normalCountry}/?zone=${zone}&${apiParam}`, config).then(res => {
        const responseData = res.data;
        const planners = responseData.planners.map(planner => planner.toString());
        const materials = responseData.material;
        return {planners, materials};
    });
}

/**
 * @function getDemands
 * @description Sends a GET request to the get-demands endpoint of the API.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request, the data is displayed on this objects: responseData
*/
function getDemands(country, plant, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/get-demands/${country.normalCountry}/?zone=${zone}&plant=${plant}&${apiParam}`, config).then(res => {
        const responseData = res.data;
        return {responseData};
    });
}

/**
 * @function postPolicy
 * @description Sends a POST request to the get-policy endpoint of the API.
 * @param {Object} args - The arguments to be sent in the body of the POST request.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request.
*/
function postPolicy(args, country, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/get-policy/${country.normalCountry}/?zone=${zone}&${apiParam}`, args, config);
}

/**
 * @function postDemands
 * @description Sends a POST request to the get-demands endpoint of the API.
 * @param {Object} args - The arguments to be sent in the body of the POST request.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request.
*/
function postDemands(args, country, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/get-demands/${country.normalCountry}/?zone=${zone}&${apiParam}`, args, config);
}

/**
 * @function postDemandsMonth
 * @description Sends a POST request to the get-demands-month endpoint of the API.
 * @param {Object} args - The arguments to be sent in the body of the POST request.
 * @returns {Promise} Axios promise for the HTTP request.
*/
function postDemandsMonth(args, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/get-demands-month/?zone=${zone}&${apiParam}`, args, config);
}

/**
 * @function postApprove
 * @description Sends a POST request to the upload-to-db endpoint of the API.
 * @param {Object} args - The arguments to be sent in the body of the POST request.
 * @returns {Promise} Axios promise for the HTTP request.
*/
function postApprove(args, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/upload-to-db/?zone=${zone}&${apiParam}`, args, config);
}

export { getPolicy, getPlannersPlants, getDemands, postPolicy, postDemands, postDemandsMonth, postApprove };